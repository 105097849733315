import { SetStateAction } from 'react'
import { Book } from '../../../../../types/library/bookTypes'
import { CollectionView, LibraryState, LibraryView, imageKeyUrlMap } from '../../../../../types/library/libraryUI'
import SignedUrlImage from '../../../../ui/SignedUrlImage/SignedUrlImage'
import ArrowLeft from '../../../../ui/icons/ArrowLeft/ArrowLeft'
import styles from './BookView.module.sass'

type BookViewProps = {
  book: Book
  setLibraryView: React.Dispatch<React.SetStateAction<LibraryState>>
  imageUrlCache: imageKeyUrlMap
  setImageUrlCache: React.Dispatch<React.SetStateAction<imageKeyUrlMap>>
  failedImageKeys: Set<string>
  setFailedImageKeys: React.Dispatch<SetStateAction<Set<string>>>
}

export const BookView: React.FC<BookViewProps> = ({
  book,
  setLibraryView,
  imageUrlCache,
  setImageUrlCache,
  failedImageKeys,
  setFailedImageKeys
}) => {

  const backToCollection = () => {
    const collectionView: CollectionView = {
      viewType: LibraryView.Collection
    }
    setLibraryView(collectionView)
  }

  const handleImageError = (bookImageKey: string) => {
    setFailedImageKeys(prev => new Set([...prev, bookImageKey]))
  }

  const getYear = (timeDateString: string) => {
    const date = new Date(Date.parse(timeDateString))
    const year = date.getFullYear()
    return year.toString()
  }

  return (
    <div className={styles.bookView}>

      <div className={styles.leftSide}>
        <div className={styles.backButtonContainer} onClick={() => backToCollection()}>
          <ArrowLeft /> <h5>Back to Collection</h5>
        </div>
        <div className={styles.tableContainer}>
        <table>
          <tr><th>TITLE:</th><td>{book.details.title}</td></tr>
          {book.details.author && <tr><th>AUTHOR:</th><td>{book.details.author}</td></tr>}
          {book.details.publicationDate && <tr><th>PUBLICATION YEAR:</th><td>{getYear(book.details.publicationDate)}</td></tr>}
          {book.details.publisher && <tr><th>PUBLISHER:</th><td>{book.details.publisher}</td></tr>}
          {book.details.description && <tr><th>DESCRIPTION:</th><td>{book.details.description}</td></tr>}
        </table>
        </div>


        {/* <div className={styles.title}>
          <h1>{book.details.title}</h1>
        </div>
        <div className={styles.bookInformation}>
          {book.details.author ? <h2>Author: {book.details.author}</h2> : null}
          {book.details.publicationDate ? <h2>Publication Date: {getYear(book.details.publicationDate)}</h2> : null}
          {book.details.publisher ? <h2>Publisher: {book.details.publisher}</h2> : null}
          {book.details.description ? <p>{book.details.description}</p> : null}
        </div> */}


      </div>

      <div className={styles.rightSide}>

        {failedImageKeys.has(book.coverImageKey) ? null :
          <div className={styles.coverImage}>
            {/* <div className={styles.imageWrapper}> */}
            <SignedUrlImage
              imageKey={book.coverImageKey}
              imageUrlCache={imageUrlCache}
              setImageUrlCache={setImageUrlCache}
              onError={() => handleImageError(book.coverImageKey)} />
            {/* </div> */}
          </div>
        }


      </div>




    </div>
  )
}

