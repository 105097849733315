import styles from './AuthenticationIcon.module.sass'

type AuthenticationIconProps = {
}

export const AuthenticationIcon: React.FC<AuthenticationIconProps> = () => {
  return (
    <svg id="authentication" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 101" >
      {/* <path d="M48.22,41.75l16.28.06v15.19h-27.86v-15.19h6.75v-5.51s-.05-6.34,7.14-6.71c0,0,7.2.37,7.07,6.71" fill="none" stroke="white" strokeMiterlimit="10" strokeWidth="5px" />
      <path d="M78.48,52.78c-.4,16.12-23.24,29.95-27.98,32.69-4.87-2.77-27.67-16.76-27.99-32.89v-26.92l27.99-11.13,28.01,11.13c0,5.47-.01,26.4-.02,27.12Z" fill="none" stroke="white" strokeMiterlimit="10" strokeWidth="5px" /> */}

      {/* <path d="M49.93,42.61l14,.05v13.79h-27.86v-13.79h6.75v-6.4s-.05-5.41,7.14-5.72c0,0,7.2.31,7.07,5.72" fill="none" stroke="white" strokeMiterlimit="10" strokeWidth="2px" vectorEffect="non-scaling-stroke"/>
      <path d="M78.48,52.78c-.4,16.12-23.24,29.95-27.98,32.69-4.87-2.77-27.67-16.76-27.99-32.89v-26.92l27.99-11.13,28.01,11.13c0,5.47-.01,26.4-.02,27.12Z" fill="none" stroke="white" strokeMiterlimit="10" strokeWidth="2px" strokeLinejoin="round"  vectorEffect="non-scaling-stroke"/> */}


      {/* <path d="M77.98,52.78c-.4,16.12-23.24,29.95-27.98,32.69-4.87-2.77-27.67-16.76-27.99-32.89v-26.92l27.99-11.13,28.01,11.13c0,5.47-.01,26.4-.02,27.12Z" fill="none" stroke="white" strokeLinejoin="round" strokeWidth="1.75px" vectorEffect="non-scaling-stroke" />

      <path d="M58.5,34.5c0-4.42-3.58-8-8-8s-8,3.58-8,8l.03,6h-6.06v21.55h28.01v-21.55h-16.98" fill="none" stroke="white" strokeMiterlimit="10" strokeWidth="1.75px" vectorEffect="non-scaling-stroke" />

      <line x1="50.51" y1="55.1" x2="50.51" y2="47.1" fill="#010101" stroke="white" strokeMiterlimit="10" strokeWidth="9px" vectorEffect="non-scaling-stroke" /> */}
      {/* <line x1="50.51" y1="60" x2="50.51" y2="38" fill="#010101" stroke="white" strokeMiterlimit="10" strokeWidth="9px" vectorEffect="non-scaling-stroke"/> */}


      {/* <path d="M82.47,53.28c-.46,16.12-26.97,29.95-32.48,32.69-5.65-2.77-32.12-16.76-32.49-32.89v-26.92l32.49-11.13,32.51,11.13c0,5.47-.01,26.4-.03,27.12Z" fill="pink" strokeWidth="2px" /> */}
      {/* <path d="M40.33,40.68l-.03-6.08c0-4.47,4.16-8.1,9.29-8.1s9.29,3.63,9.29,8.1v6.08" fill="orange" strokeWidth="2px" />
      <polygon points="57.6 40.68 63.6 40.68 63.6 60.5 35.5 60.5 35.5 40.68 41.58 40.68 57.6 40.68" fill="green" strokeWidth="2px" /> */}

{/* 
      <path d="M82.47,53.28c-.46,16.12-26.97,29.95-32.48,32.69-5.65-2.77-32.12-16.76-32.49-32.89v-26.92l32.49-11.13,32.51,11.13c0,5.47-.01,26.4-.03,27.12Z" fill="none" stroke="#000" strokeLinejoin="round" strokeWidth="2px" /> */}


      {/* <path d="M40.33,40.68l-.03-6.08c0-4.47,4.16-8.1,9.29-8.1s9.29,3.63,9.29,8.1v6.08" fill="none" stroke="#000" strokeMiterlimit="10" /> */}

      {/* <polygon points="57.6 40.68 63.6 40.68 63.6 60.5 35.5 60.5 35.5 40.68 41.58 40.68 57.6 40.68" fill="white" stroke="white" strokeMiterlimit="10" strokeWidth=".89px" /> */}



      <path d="M82.47,51.28c-.46,16.12-26.97,29.95-32.48,32.69-5.65-2.77-32.12-16.76-32.49-32.89v-26.92l32.49-11.13,32.51,11.13c0,5.47-.01,26.4-.03,27.12Z" fill="none" stroke="white" strokeMiterlimit="10" strokeWidth="1.75px" vectorEffect="non-scaling-stroke"/>
      {/* <path d="M40.33,42.68 v-7 c0-4.47,4.16-8.1,9.29-8.1 s9.29,3.63,9.29,8.1 v7" fill="none" stroke="white" strokeMiterlimit="10" strokeWidth="1.3px" vectorEffect="non-scaling-stroke"/> */}
      <path d="M40.33,42.68 v-7 c0-4.47,4.16-8.1,9.29-8.1 s9.29,3.63,9.29,8.1 v7" fill="none" stroke="white" strokeMiterlimit="10" strokeWidth="1.3px" vectorEffect="non-scaling-stroke"/>
      <polygon points="64.6 40 64.6 58.5 34.5 58.5 34.5 40 " fill="white" stroke="none" strokeMiterlimit="10" strokeWidth="1px" vectorEffect="non-scaling-stroke"/>

    </svg>
  )
}