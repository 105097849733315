import styles from './PortfolioDockIcon.module.sass'

type PortfolioDockIconProps = {
}

export const PortfolioDockIcon:React.FC<PortfolioDockIconProps> = () => {
  return(
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
  <path fill="none" stroke="gray" stroke-miterlimit="10" stroke-width="5" d="M49.21 32.21H21.17s-9.87-.72-9.64 9.64v33.89s-1.26 5.47 7.48 5.47h64.1s4.19 1.16 5.42-5.42V41.85s.8-9.66-10.48-9.65H49.22Z"/>
  <path fill="none" stroke="gray" stroke-miterlimit="10" stroke-width="5" d="M33.48 32.21v-7.5s-.68-10 6.83-10h19.38c7.51 0 6.83 10 6.83 10v7.5"/>
  <path fill="none" stroke="gray" stroke-linecap="round" stroke-miterlimit="10" stroke-width="5" d="M28.4 34.26v13.06c0 .18.05.35.14.5l4.03 6.73c.43.72 1.56.72 1.99 0l3.88-6.48c.09-.15.14-.33.14-.5V34.26c0-.58-.51-1.05-1.14-1.05h-7.92c-.63 0-1.14.47-1.14 1.05ZM61.34 34.26v13.06c0 .18.05.35.14.5l4.03 6.73c.43.72 1.56.72 1.99 0l3.88-6.48c.09-.15.14-.33.14-.5V34.26c0-.58-.51-1.05-1.14-1.05h-7.92c-.63 0-1.14.47-1.14 1.05Z"/>
</svg>
  )
}