import { PG } from '../ui/PG/PG';
import styles from './TopMenu.module.sass'
import { Time } from './Time/Time';
import { ToolTip } from '../ui/ToolTip/ToolTip';
import { useState } from 'react';
import { Notifications } from './Notifications/Notifications';
import { Authentication } from './Authentication/Authentication';

const TopMenu = () => {

  const [authenticationIsOpen, setAuthenticationIsOpen] = useState(false)
  const [notificationsCentreIsOpen, setNotificationsCentreIsOpen] = useState(false)


  return (
    <div className={styles.topMenu}>
      <div className={styles.leftHandSide}>
        <div className={styles.logoOuterBackground}>
          <div className={styles.logo}>
            <PG />
          </div>
        </div>
      </div>
      <div className={styles.rightHandSide}>

        <div className={styles.time}>
          <Time />
        </div>

        <div className={styles.authentication}>
          <ToolTip text="Log In" position="bottom" marginTopPx={5} keepHidden={authenticationIsOpen}>
            <Authentication setAuthenticationIsOpen={setAuthenticationIsOpen} />
          </ToolTip>
        </div>

        <ToolTip text="Notifications" position="bottom" marginTopPx={5} keepHidden={notificationsCentreIsOpen}>
          <Notifications setNotificationsCentreIsOpen={setNotificationsCentreIsOpen} />
        </ToolTip>

      </div>
    </div >
  )
}

export default TopMenu;