import { SubjectIndex } from '../../../../../../types/library/subjectIndexTypes'
import styles from './LeftSidePanel.module.sass'
import { BookCollection } from '../../../../../../types/library/bookTypes'
import { SearchParams } from '../../../../../../types/library/librarySearch'
import { SearchFilters } from './SearchFilters/SearchFilters'
import { SetStateAction } from 'react'
import { InfoSection } from './InfoSection/InfoSection'

type LeftSidePanelProps = {
  subjectIndex: SubjectIndex,
  error: any,
  bookCollection: BookCollection
  setBookResults: React.Dispatch<React.SetStateAction<BookCollection>>
}

export const LeftSidePanel: React.FC<LeftSidePanelProps> = ({
  subjectIndex,
  error,
  bookCollection,
  setBookResults
}) => {

  return (

    <div className={styles.leftSidePanel}>

      {/* <InfoSection /> */}

      <SearchFilters
        subjectIndex={subjectIndex}
        error={error}
        bookCollection={bookCollection}
        setBookResults={setBookResults} />
    </div>
  )
}