import TabMenu from "./TabMenu/TabMenu"
import { TabContentProps, TabContent } from "./TabContent/TabContent"
import styles from './TabLayout.module.sass'
import React, { useEffect, useState } from "react"
import { Tab } from "./Tab/Tab"

type TabLayoutProps = {
}

const TabLayout: React.FC<TabLayoutProps> = ({ children }) => {

  const getTabs = (children: React.ReactNode): React.ReactElement[] => {
    return React.Children.toArray(children).flatMap(child => {
      if (React.isValidElement(child)) {
        if (child.type === Tab) {
          return child
        }
      }
      return []
    })
  }

  const getTitles = (tabs: React.ReactElement[]) => {
    let titles: string[] = []
    tabs.map(child => {
      if (React.isValidElement<TabContentProps>(child) && child.type === Tab) {
        const title = child.props.title
        titles.push(title)
      }
    })
    return titles
  }

  const tabs = getTabs(children)
  const titles = getTitles(tabs)

  const [currentTab, updateCurrentTab] = useState(titles[0])

  useEffect(() => {
    console.log("currentTab is:", currentTab)
  }, [currentTab])

  return (
    <div className={styles.tabLayout}>

      <div className={styles.tabMenuContainer}>
        <TabMenu
          titles={titles}
          currentTab={currentTab}
          updateCurrentTab={updateCurrentTab}
        />
      </div>

      {tabs.map(tab => {
        if (React.isValidElement<TabContentProps>(tab) && tab.type === Tab) {
          const title = tab.props.title
          if (title == currentTab)
            return (
              <TabContent title={title}>
                {tab.props.children}
              </TabContent>
            )
        }
      })}

    </div>
  )
}

export default TabLayout