import { useState } from 'react'
import { SubjectIndex } from '../../../../../../../types/library/subjectIndexTypes'
import styles from './SearchFilters.module.sass'
import { SubjectBox } from './SubjectBox/SubjectBox'
import React from 'react'
import { BookCollection } from '../../../../../../../types/library/bookTypes'
import useSearchCollection from '../../../../../../../hooks/useSearchCollection'
import { SearchParams } from '../../../../../../../types/library/librarySearch'

type SearchFiltersProps = {
  subjectIndex: SubjectIndex,
  error: any,
  bookCollection: BookCollection
  setBookResults: React.Dispatch<React.SetStateAction<BookCollection>>
}

export const SearchFilters: React.FC<SearchFiltersProps> = ({
  subjectIndex,
  error,
  bookCollection,
  setBookResults
}) => {

  const [selectedSubjects, setSelectedSubjects] = useState<Set<string>>(new Set())
  const [searchParams, setSearchParams] = useState<SearchParams>({ subjects: new Set<string>() })

  useSearchCollection(searchParams, bookCollection, setBookResults)

  const clickSubject = (subject: string) => {
    setSelectedSubjects(prev => {
      const newSelectedSubjects = new Set(prev)
      if (prev.has(subject)) newSelectedSubjects.delete(subject)
      else newSelectedSubjects.add(subject)
      setSearchParams({ ...searchParams, subjects: newSelectedSubjects })
      return newSelectedSubjects
    })
  }

  return (

    <div className={styles.searchFilters}>

      <h3>SUBJECTS</h3>

      <div className={styles.subjects}>

        {
          subjectIndex ? (
            <ul>
              {Object.entries(subjectIndex)
                .sort(([a], [b]) => a.localeCompare(b))
                .map(([subject, bookNameList]) => (
                  <li
                    key={subject}
                    onClick={() => clickSubject(subject)}>
                    <SubjectBox selected={selectedSubjects.has(subject)} /> {subject}
                  </li>
                ))
              }
            </ul>
          ) : error ? (
            <p>Error loading data</p>
          ) : (
            <p>Loading...</p>
          )
        }

      </div>
      <div className={styles.categories}>
      </div>
    </div>
  )
}