import { SetStateAction, useEffect } from 'react';
import { SearchParams } from '../types/library/librarySearch';
import { BookCollection } from '../types/library/bookTypes';

const useSearchCollection = (
  searchParams: SearchParams,
  bookCollection: BookCollection,
  setBookResults: React.Dispatch<SetStateAction<BookCollection>>
) => {
  let result: BookCollection = {}
  useEffect(() => {
    if (bookCollection) {
      if (searchParams.subjects && searchParams.subjects.size > 0) {
        searchParams.subjects.forEach(subject => {
          Object.entries(bookCollection).forEach(([bookRef, book]) => {
            if (book.subjects) {
              if (book.subjects.includes(subject)) {
                result[bookRef] = book
              }
            }
          })
        })
      } else {
        result = bookCollection
      }
      if (result) {
        setBookResults(result)
      }
    }
  }, [searchParams, bookCollection])
}

export default useSearchCollection