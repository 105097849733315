import { WindowStates } from "../types/types";

export const initialWindowStates: WindowStates = {

  'profile': {
    isOpen: false,
    isFullScreen: false,
    isMinimised: false,
    isWindowInFront: false,
    zPosition: 0,
    zIndex: 0
  },
  'portfolio': {
    isOpen: false,
    isFullScreen: false,
    isMinimised: false,
    isWindowInFront: false,
    zPosition: 0,
    zIndex: 0
  },
  'library': {
    isOpen: false,
    isFullScreen: false,
    isMinimised: false,
    isWindowInFront: false,
    zPosition: 0,
    zIndex: 0
  },
  'contact': {
    isOpen: false,
    isFullScreen: false,
    isMinimised: false,
    isWindowInFront: false,
    zPosition: 0,
    zIndex: 0
  },
  'apps': {
    isOpen: true,
    isFullScreen: false,
    isMinimised: false,
    isWindowInFront: false,
    zPosition: 0,
    zIndex: 0
  }
}