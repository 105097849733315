import styles from './AppLabel.module.sass'

type AppLabelProps = {
  appName: string
  isOpen: boolean
}

const AppLabel: React.FC<AppLabelProps> = ({ appName, isOpen }) => {

const openLabelIfOpen = isOpen && styles.labelBox

  return (
    <div className={styles.outerContainer}>
      <div className={`${styles.labelBox} ${openLabelIfOpen}`}>
        <h3>{appName}</h3>
      </div>
    </div>
  )
}

export default AppLabel