import { ProfileTab } from "../ProfileTab/ProfileTab"

const DevOps: React.FC = () => {
  return (
    <ProfileTab>
      <div>
        <p>DevOps:.</p>
        <ul>
          <li>
            Deployment pipelines, Netlify
          </li>
          <li>
            CI/CD
          </li>
          <li>
            Version control: Git. GitKraken, GitLab
          </li>
          <li>
            AWS - lambda,
          </li>
          <li>
            Azure
          </li>
          <li>
            Google Cloud
          </li>
          <li>
            IDEs - VSCode, IntelliJ, Android Studio
          </li>
          <li>
            Docker, Kubernetes
          </li>
        </ul>
      </div>
    </ProfileTab>
  )
}

export default DevOps