type InfoButtonIconProps = {
  filter: boolean
}

const InfoButtonIcon: React.FC<InfoButtonIconProps> = ({ filter }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="info-icon" viewBox="0 0 100 100">

      <defs>
        <filter id="f1" x="-50%" y="-50%" width="200%" height="200%">
          <feOffset result="offOut" in="SourceAlpha" dx="0" dy="0" />
          <feGaussianBlur result="blurOut" in="offOut" stdDeviation="1" />
          <feComponentTransfer>
            <feFuncA type="linear" slope="14" />
          </feComponentTransfer>
          <feMerge>
            <feMergeNode />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>

      {filter && <circle cx="50" cy="50" r="30" fill="none" stroke="rgb(255,0,0)" stroke-miterlimit="10" stroke-width="4" filter="url(#f1)" />}
      <circle cx="50" cy="50" r="30" fill="white" stroke="#000" stroke-miterlimit="10" stroke-width="4" />
      <circle cx="50" cy="33.25" r="2.73" stroke="#000" stroke-miterlimit="10" stroke-width="2.43" />
      <path stroke="#000" stroke-miterlimit="10" stroke-width="1.69" d="M58.73 64.24v3h-17v-3h6v-19h-6v-3h11v22h6z" />
    </svg>

  )
}

export default InfoButtonIcon

// type InfoButtonProps = {
//   filter: boolean
// }

// const InfoButton: React.FC<InfoButtonProps> = ({ filter }) => {
//   return (
//     <svg xmlns="http://www.w3.org/2000/svg" id="info-icon" viewBox="0 0 120 120" width="120" height="120">
//       <defs>
//         <filter id="f1" x="-0.2" y="-0.2" width="140%" height="140%">
//           <feOffset result="offOut" in="SourceAlpha" dx="3" dy="3" />
//           <feGaussianBlur result="blurOut" in="offOut" stdDeviation="5" />
//           <feComponentTransfer>
//             <feFuncA type="linear" slope="0.2"/>
//           </feComponentTransfer>
//           <feMerge>
//             <feMergeNode />
//             <feMergeNode in="SourceGraphic" />
//           </feMerge>
//         </filter>
//       </defs>
//       {/* Adjust the circle's cx and cy if needed to recenter */}
//       <circle cx="60" cy="60" r="30" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="4" filter="url(#f1)" />
//       <circle cx="60" cy="43.25" r="2.73" fill="#000" />
//       <path fill="#000" stroke="#000" stroke-miterlimit="10" stroke-width="1.69" d="M68.73 74.24v3h-17v-3h6v-19h-6v-3h11v22h6z" />
//     </svg>
//   )
// }

// export default InfoButton