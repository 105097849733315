import styles from './DockOverlayRight.module.sass'

type DockOverlayRightProps = {
}

export const DockOverlayRight: React.FC<DockOverlayRightProps> = () => {
  return (

    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
      {/* <rect width="100" height="100" x="0" y="0" fill="rgb(150,150,150)" fillOpacity="0.3"/> */}
      <path d="M100,0 v100 l-90,-5.5 v-88.5 z" fill="rgb(120,120,120)" fillOpacity="0.3" />
    </svg>
  )
}