function ContactIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="Letter" viewBox="0 0 128 128">
      <g id="Letter-2">
        <path
          id="upper-side-highlight"
          fill="#f8dc78"
          d="M54.18 12.66c3.02-1.62 7.89-1.58 11.39.42 2.2 1.26 3.59 2.19 6.13 4.05 7.48 5.46 13.76 10.27 21.42 15.86l20.38 17.95c.32.1-7.43 1.9-8.25 2.3L54.18 12.66z"
        ></path>
        <path
          id="envelope-inner-upper"
          fill="#eac450"
          d="M106.08 53.61l-96.54-4.5v-.07l44.1-35.19c1.47-1.17 3.67-1.07 5.24.24l47.2 39.52z"
        ></path>
        <path
          id="back-inside-envelope"
          fill="#c3a343"
          d="M106.08 53.68l3.01 56.94-98.4-4.77-1.14-56.44v-.3l96.53 4.5c-.03.05-.02.07 0 .07z"
        ></path>
        <path
          id="outline"
          fill="none"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M114.56 101.35l-5.82 7.58c-.01.07.07.01 0 0l-97.99-2.97c-.07-.01-.07-.15-.07-.22l-1.9-56.67v-.37l44.1-35.19c.44-.35.88-.56 1.37-.81 3.1-1.72 5.92-2.73 11.39.42 2.2 1.27 3.59 2.19 6.13 4.05 7.48 5.45 13.77 10.27 21.42 15.85l20.38 17.95v.02l.99 50.37z"
        ></path>
        <g id="paper-piece">
          <path
            id="top-white"
            fill="#fff"
            d="M99.48 48.89L20.59 70.42l-1.34-23.58.59-25.25 77.91 2.84 1.73 24.46"
          ></path>
          <path
            id="middle-shade"
            fill="#f2f2f2"
            d="M99.45 49.64L19.5 48.57l-.22 24.78 79.47 1.73"
          ></path>
          <path
            id="crease-shade"
            fill="#f7f7f7"
            d="M99.06 49l-79.82-.71.06 2.2 80.18.56"
          ></path>
          <path
            id="lower-shade"
            fill="#e8e8e8"
            d="M97.31 74.5L16.7 73.31l-.89 14.06 86.5 3.39"
          ></path>
          <path
            id="paper-outline"
            fill="none"
            stroke="#000"
            strokeMiterlimit="10"
            strokeWidth="1.5"
            d="M98.6 72.42c-.01.43.15-.41 0 0 0 0 1.67 24.16 1.39 24.16l-75.47-2.24c-.28 0-3.94-24.34-3.94-24.34l-1.4-22.4.66-26.01 79.22 2.59.12 24.09-.59 24.15z"
          ></path>
          <path
            id="writing"
            fill="none"
            stroke="#000"
            strokeMiterlimit="10"
            strokeWidth="1.5"
            d="M26 31.56l29.8.87M26 38.89l21.48.62M26 41.63l13.63.4m41.04-8.9l10.55.39M31.59 62.66l55.44 1.6m-55.51-4.7l55.44 1.6m-55.29 4.6l55.44 1.6m-71.3 1.1l55.44 1.61"
          ></path>
        </g>
        <path
          id="right-outline"
          fill="none"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M64.98 82.56L106 53.66l.15-.09 6.18-2.06a.918.918 0 011.29.79l.91 48.6c.01.28-.1.55-.32.73l-4.67 8.36c-.3.25-.56-.04-.9-.24l-43.6-25.67a.912.912 0 01-.06-1.53z"
        ></path>
        <path
          id="right-side"
          fill="#f8dc78"
          d="M106.44 54.27l6.21-2.07h.04c.11 0 .18.05.19.14l.91 48.61-.06.13-4.61 7.55s-.37.32-.39.32"
        ></path>
        <path
          id="right-side-2"
          fill="#eac450"
          d="M108.33 108.7l-42.9-25.26c-.07-.04-.08-.09-.08-.13s.01-.1.07-.14l41.02-28.9 2.3 54.66"
        ></path>
        <path
          id="shadow-right"
          fill="#c3a343"
          d="M108.74 108.94l-.42-.24-42.9-25.26c-.07-.04-.08-.09-.08-.13s.01-.1.07-.14l.43-.3 42.88 25.71.02.36z"
        ></path>
        <path
          id="left-fold"
          fill="#eac450"
          stroke="#000"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M8.32 49c-.07-.05-.04.72-.04.81l1.91 58.01 43.23-24.54L8.32 49z"
        ></path>
        <path
          id="bottom-fold"
          fill="#eac450"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M56.06 80.83l-45.87 26.99 99.22 2.27-47.75-29.13s-2.6-1.3-5.6-.13z"
        ></path>
        <path
          id="shadow-left"
          fill="#c3a343"
          d="M10.83 104.39l38.66-23.16 1.92 1.47-40.51 23.83-.07-2.14z"
        ></path>
      </g>
    </svg>
  );
}

export default ContactIcon;