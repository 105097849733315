// import { useEffect, useState } from 'react'
// import styles from './Time.module.sass'

// type TimeProps = {
// }

// const [time, setTime] = useState<Date>(new Date(Date.now()))

// useEffect(() => {
//   const interval = setInterval(() => { setTime(new Date(Date.now())) }, 1000)
// })


// const getDoubleDigitString = (n: number) => (n < 10 ? "0" + n.toString() : n.toString())
// const hours = getDoubleDigitString(time.getHours())
// const minutes = getDoubleDigitString(time.getMinutes())
// const seconds = getDoubleDigitString(time.getSeconds())

// export const Time: React.FC<TimeProps> = () => {
//   return (
//     <>
//       <h3>{hours}:{minutes}:{seconds}</h3>
//     </>
//   )
// }

import { useState, useEffect } from 'react';
import styles from './Time.module.sass';

type TimeProps = {};

export const Time: React.FC<TimeProps> = () => {
  const [time, setTime] = useState<Date>(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000);

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, []); // Empty dependency array ensures this runs once on mount

  const getDoubleDigitString = (n: number) => (n < 10 ? '0' + n.toString() : n.toString());
  const hours = getDoubleDigitString(time.getHours());
  const minutes = getDoubleDigitString(time.getMinutes());
  const seconds = getDoubleDigitString(time.getSeconds());

  return (
    <>
      <h3 className={styles.time}>{hours}:{minutes}:{seconds}</h3>
    </>
  );
};
