export type Position = {
  top: number;
  left: number;
  bottom: number;
  right: number;
}

export type InitialPosition = {
  top: number | string;
  left: number | string;
  bottom: number | string;
  right: number | string;
}

export type Size = {
  height: number;
  width: number;
}

export type BoundingArea = {
  maxHeight: number,
  maxWidth: number,
  minTop: number,
  minBottom: number,
  minLeft: number,
  minRight: number
}

export type WindowStates = {
  [windowId: string]: {
    isOpen: boolean;
    isFullScreen: boolean;
    isMinimised: boolean;
    isWindowInFront: boolean;
    zPosition: number;
    zIndex: number;
  }
}

export type StringPair = [string, string]

export type StringPairArray = StringPair[]


export type Notification = {
  subject: string;
  details?: StringPairArray;
  isNew: boolean;
  source: string;
  action: string;
  timeCreated: number
}

// export type NotificationStates = {
//   [windowId: string]: {
//     notifications: Notification[];
//     currentMessageIndex: number;
//   }
// }

// export enum WindowStatus {
//   OpenResizable = "collectionView",
//   Closed = "bookView",
//   Minimised = "minimised",
// }

export type InitialWindowConfigMap = {
  [windowId: string]: InitialWindowConfig
}

export type InitialWindowConfig = {
  title: string;
  initialLayoutParams: InitialLayoutParams;
  minimumSize: Size;
  content: JSX.Element;
  dockIcon: JSX.Element;
  infoText: string
}

export type InitialisedWindowValuesIndex = {
  [windowId: string]: InitialisedWindowValues

}

export type InitialisedWindowValues = {
  title: string;
  minimumSize: Size;
  layoutParams: LayoutParams;
  content: JSX.Element;
  dockIcon: JSX.Element;
  infoText: string
}

export enum LandingAnimationStatus {
  Start = 'start',
  Loading = 'loading',
  Done = 'done'
}

export type LayoutParams = {
  top: number,
  left: number,
  width: number,
  height: number
}

export type InitialLayoutParams = {
  top: number | string,
  left: number | string,
  width: number | string,
  height: number | string
}