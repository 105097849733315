import { Notification } from '../../../../types/types'
import CloseButton from '../../../ui/CloseButton/CloseButton'
import styles from './DetailedNotification.module.sass'

type DetailedNotificationProps = {
  notification: Notification
}

export const DetailedNotification: React.FC<DetailedNotificationProps> = ({ notification }) => {
  return (
    <div className={`global_box ${styles.container}`}>
      {/* <div className={styles.topRow}>
        <h3>NOTIFICATION DETAILS</h3>
        <CloseButton />
      </div> */}
      <div className={styles.topRow}>
        <h3>HIDE DETAILS</h3>
        {/* <h3>HIDE DETAILS</h3> */}
        {/* <CloseButton /> */}
      </div>
      <table>
        {/* <tr><th colSpan={2}>{notification.subject}</th></tr> */}
        {/* <tr><th colSpan={2}>NOTIFICATION DETAILS</th></tr> */}
        <tr><td>Subject:</td><td>{notification.subject}</td></tr>
        {notification.source && notification.action ?
          <tr><td>{notification.source}:</td><td>{notification.action}</td></tr>
          : null
        }
        <tr><td>Time:</td><td>{notification.timeCreated.toLocaleString()}</td></tr>
        {notification.details && notification.details.length > 0 ?
          notification.details?.map(([string1, string2]) => {
            return <tr><td>{string1}:</td><td>{string2}</td></tr>
          })
          : null
        }
      </table>
    </div>
  )
}