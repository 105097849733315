import { useContext, useState } from 'react';
import useWindowManager from '../../../../../hooks/useWindowManager';
import AppContext from '../../../../App/AppContext';
import CloseButton from '../../../../ui/CloseButton/CloseButton'
import MinimiseButton from '../../../../ui/MinimiseButton/MinimiseButton';
import styles from './WindowTop.module.sass'
import desktopStyles from './WindowTopDesktop.module.sass'
import { EnterFullScreenIcon } from '../../../../ui/fullScreenIcons/EnterFullScreenIcon/EnterFullScreenIcon';
import { ExitFullScreenIcon } from '../../../../ui/fullScreenIcons/ExitFullScreenIcon/ExitFullScreenIcon';
import InfoButtonIcon from '../../../../ui/InfoButtonIcon/InfoButtonIcon';
import { InfoButtonModal } from './InfoButtonModal/InfoButtonModal';

type WindowTopDesktopProps = {
  title: string;
  windowId: string;
  mouseDownOnWindowTop: (e: React.MouseEvent<HTMLDivElement>) => void;
  setShowInfoBox: React.Dispatch<React.SetStateAction<boolean>>
}

const WindowTopDesktop: React.FC<WindowTopDesktopProps> = ({
  title,
  windowId,
  mouseDownOnWindowTop,
  setShowInfoBox
}) => {

  const { windows, setWindows } = useContext(AppContext);

  const {
    closeWindow,
    isWindowFullScreen,
    toggleFullScreen,
    setMinimised,
  } = useWindowManager(windows, setWindows)

  const addClassIfFullScreen = () => {
    const resultClass =
      windows[windowId].isFullScreen ?
        desktopStyles.fullScreen :
        null
    return resultClass
  }


  return (

    <div
      className={desktopStyles.windowTop + ' ' + `${addClassIfFullScreen()}`}
      onMouseDown={mouseDownOnWindowTop}>

      <div className={desktopStyles.topLeftContainer}>
        <div className={desktopStyles.leftButtonGroup}>
          <InfoButtonModal setShowInfoBox={setShowInfoBox} />
        </div>
        <div className={styles.strokes}></div>
      </div>

      <div className={styles.title}>
        <h3>{title}</h3>
      </div>

      <div className={desktopStyles.topRightContainer}>
        <div className={styles.strokes}></div>

        <div className={desktopStyles.rightButtonGroup}>

          <div className={`${desktopStyles.minimiseIconContainer} ${desktopStyles.button}`}
            onClick={(e) => setMinimised(windowId, true)}
            onMouseDown={(e) => e.stopPropagation()}>
            <MinimiseButton />
          </div>

          <div className={`${desktopStyles.fullScreenIconContainer} ${desktopStyles.button}`}
            onClick={(e) => toggleFullScreen(windowId)}
            onMouseDown={(e) => e.stopPropagation()}>
            {isWindowFullScreen(windowId) ?
              <ExitFullScreenIcon /> :
              <EnterFullScreenIcon />
            }
          </div>

          <div className={`${desktopStyles.closeButtonContainer} ${desktopStyles.button}`}
            onClick={(e) => closeWindow(windowId)}
            onMouseDown={(e) => e.stopPropagation()}>
            <CloseButton />
          </div>

        </div>

      </div>
    </div>
  )
}

export default WindowTopDesktop