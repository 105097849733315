import styles from '../PortfolioIcon.module.sass'

const PortfolioIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="bag1"
      viewBox="0 0 128 128"
      className={styles.appIcon}
    >
      <g id="bag" stroke="#000" strokeMiterlimit="10" strokeWidth="1.5"
        
      >
        <path
          fill="#fff"
          d="M50.2 31.6l-10.11 3.37c-1.1.37-1.85 1.39-1.85 2.55v7.76c0 1.82 1.8 3.12 3.54 2.54l2.23-.74 5.47-1.82 2.42-.81c1.1-.37 1.84-1.39 1.84-2.55v-7.76c0-1.82-1.8-3.12-3.53-2.54zm-.08 9.16c0 .59-.37 1.11-.94 1.31l-3.84 1.27-1.33.45c-.12.04-.25.07-.38.07-.75.04-1.43-.55-1.43-1.36v-3.97c0-.59.38-1.12.95-1.31l5.18-1.73c.88-.29 1.8.37 1.8 1.31v3.97zm32.6-20.66l-10.11 3.37c-1.1.37-1.85 1.39-1.85 2.55v7.76c0 1.82 1.8 3.12 3.54 2.54l2.23-.74L82 33.76l2.42-.81c1.1-.37 1.84-1.39 1.84-2.55v-7.76c0-1.82-1.8-3.12-3.53-2.54zm-.08 9.16c0 .59-.37 1.11-.94 1.31l-3.84 1.27-1.33.45c-.12.04-.25.07-.38.07-.75.04-1.43-.55-1.43-1.36v-3.97c0-.59.38-1.12.95-1.31l5.18-1.73c.88-.29 1.8.37 1.8 1.31v3.97z"
        ></path>
        <path
          fill="#0012ff"
          d="M42.74 36.96l6-1.5s1-8-.5-7c-1.39.93 1.03-11.3 8.1-14.57 3.4-1.57 9.66-2.54 11.15-2.39 5 .5 9.74 3.96 7.74 7.46 0 0-.5.5-.42 6.71l3-1 3.92-.71s0-5.98-1.07-6.55c-.26-.14-.49-.31-.56-.6-.47-2.18-1.62-6.81-8.62-9.31-2.86-1.02-7.88-1.88-12.5-.5-5.97 1.78-14.63 6.18-15.26 21.96 0 0-2 0-1 8z"
        ></path>
        <path
          id="bag-back"
          fill="#000fd4"
          d="M111.74 29.96l-.08.08c-1.96-.54-4.67-.42-8.42.92l-3.27 1.05-2.74.89-5.99 1.93-3 .97-16 5.16-8.5 2.78-2.73.89-6 1.96-3 .98-5.77 1.89s-14 2-15 22-.5 48.5-.5 48.5l-10.5-4s-6 .5-4.5-8.5l.5-46s0-15 15.5-18l54-18s8-4.5 13.5-.5l12.5 5z"
        ></path>
        <path
          id="bag-front"
          fill="#0012ff"
          d="M116.74 37.96l-.5 48c2 8.5-6.5 10-6.5 10l-70 23.5c-8 3.5-9 .5-9 .5s-.5-28.5.5-48.5 15-22 15-22l5.77-1.89 3-.98 6-1.96 2.73-.89 8.5-2.78 16-5.16 3-.97 5.99-1.93 2.74-.89 3.27-1.05c3.75-1.34 6.46-1.46 8.42-.92 5.35 1.49 5.08 7.92 5.08 7.92z"
        ></path>
      </g>
      <g
        id="left-strap-buckle"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        

      >
        <path
          fill="#fff"
          d="M62.72 40.6l-10.11 3.37c-1.1.37-1.85 1.39-1.85 2.55v7.76c0 1.82 1.8 3.12 3.54 2.54l2.23-.74L62 54.26l2.42-.81c1.1-.37 1.84-1.39 1.84-2.55v-7.76c0-1.82-1.8-3.12-3.53-2.54zm-.08 9.16c0 .59-.37 1.11-.94 1.31l-3.84 1.27-1.33.45c-.12.04-.25.07-.38.07-.75.04-1.43-.55-1.43-1.36v-3.97c0-.59.38-1.12.95-1.31l5.18-1.73c.88-.29 1.8.37 1.8 1.31v3.97z"
        ></path>
        <path
          fill="#3345ff"
          d="M56.24 52.46l6-1.5s-1 4.5 0 4l2-.5V72.2c0 .17-.06.33-.17.46l-3.75 4.22c-.05.05-.12.07-.18.04l-4.7-2.35a.343.343 0 01-.19-.31V56.98l1.43-.36c.41-.1.74-.43.8-.86.09-.68-.04-1.81-1.23-3.29z"
        ></path>
      </g>
      <g
        id="right-strap-buckle"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        

      >
        <path
          fill="#fff"
          d="M95.72 29.64l-10.11 3.37c-1.1.37-1.85 1.39-1.85 2.55v7.76c0 1.82 1.8 3.12 3.54 2.54l2.23-.74L95 43.3l2.42-.81c1.1-.37 1.84-1.39 1.84-2.55v-7.76c0-1.82-1.8-3.12-3.53-2.54zm-.08 9.16c0 .59-.37 1.11-.94 1.31l-3.84 1.27-1.33.45c-.12.04-.25.07-.38.07-.75.04-1.43-.55-1.43-1.36v-3.97c0-.59.38-1.12.95-1.31l5.18-1.73c.88-.29 1.8.37 1.8 1.31v3.97z"
        ></path>
        <path
          fill="#3345ff"
          d="M89.24 41.5l6-1.5s-1 4.5 0 4l2-.5v17.74c0 .17-.06.33-.17.46l-3.75 4.22c-.05.05-.12.07-.18.04l-4.7-2.35a.343.343 0 01-.19-.31V46.02l1.43-.36c.41-.1.74-.43.8-.86.09-.68-.04-1.81-1.23-3.29z"
        ></path>
        <path
          fill="none"
          d="M101.5 26L58.84 39.81c-4.75 1.54-9.57 2.91-14.47 3.86C20.68 48.26 23.5 63 23.5 71v12.5"
        ></path>
      </g>
      <path
        fill="#3345ff"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M55.78 47l6-1.5s.88-8.16-.5-7c-1.22 1.03.57-14.22 2.34-16.88 3.99-5.99 15.54-5.21 18.12-3.58S88.91 23.3 88.28 29c0 0-.5.5-.42 6.71 1.63-.69 5.16-1.48 6.92-1.71 0 0 0-5.98-1.07-6.55-1.54-2.59-3.64-13.42-11.97-15.12-4.35-.89-15.96-.44-19.1 2.7-3.42 3.42-5.55 8.66-5.86 23.97 0 0-2 0-1 8z"

      ></path>
    </svg>
  );
}

export default PortfolioIcon;