import { CheckBox } from "../../../../../../../ui/CheckBox/CheckBox"
import styles from './SubjectBox.module.sass'

type SubjectBoxProps = {
  selected: boolean
}

export const SubjectBox: React.FC<SubjectBoxProps> = ({ selected }) => {
  return (
    <div className={styles.subjectBox}>
      <CheckBox selected={selected} />
    </div >
  )
}