import { useState } from "react";
import { LandingAnimationStatus as Status } from "../../types/types.d";
import { LandingAnimation } from '../ui/LandingAnimation/LandingAnimation'
import styles from './LandingPage.module.sass'
import { LandingWindow } from "../ui/LandingWindow/LandingWindow";

type LandingPageProps = {
  setFirstVisit: React.Dispatch<React.SetStateAction<boolean>>
}



export const LandingPage: React.FC<LandingPageProps> = ({ setFirstVisit }) => {

  const [animationStatus, setAnimationStatus] = useState<Status>(Status.Start)

  const handleClick = () => {
    setAnimationStatus(Status.Loading)
    // if (animationEnd) setFirstVisit(false)
  }

  return (
    <div className={styles.landingPage} >
      <div
        className={styles.logoContainer}
        onClick={animationStatus == Status.Start ? () => handleClick() : undefined}>

        <LandingWindow
          status={animationStatus}
          setStatus={setAnimationStatus}
          setFirstVisit={setFirstVisit}
        />
      </div>
    </div>
  )
}