import { useState, useRef, FormEvent, useContext } from 'react';
import styles from './ContactWindow.module.sass';
import { ContactFormData } from '../../../../types/contact/sendFormTypes';
import AppContext from '../../../App/AppContext';
import useWindowManager from '../../../../hooks/useWindowManager';
import { Notification } from '../../../../types/types';

const ContactWindow = () => {

  const [toastMessage, setToastMessage] = useState<string>("")
  const [showToast, setShowToast] = useState(false)
  const [toastColor, setToastColor] = useState("black")
  const [showAltContact, setShowAltContact] = useState(false)
  const [isEmailFocused, setEmailFocused] = useState(false)
  const [isMessageFocused, setMessageFocused] = useState(false)

  const emailInputRef = useRef<HTMLInputElement>(null)
  const messageInputRef = useRef<HTMLTextAreaElement>(null)

  const handleEmailFocus = () => setEmailFocused(true)
  const handleMessageFocus = () => setMessageFocused(true)
  const handleEmailBlur = () => setEmailFocused(false)
  const handleMessageBlur = () => setMessageFocused(false)



  const { notifications: messages, setNotifications: setMessages } = useContext(AppContext)
  // const { addNewMessage } = useMessageManager(messages, setMessages, 'contact')

  const handleEmailSectionClick = () => {
    if (emailInputRef.current) {
      emailInputRef.current.focus();
    }
  };

  const handleMessageSectionClick = () => {
    if (messageInputRef.current) {
      messageInputRef.current.focus();
    }
  };

  const url = 'https://petergregory.dev/.netlify/functions/sendEmail'

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()
    try {
      // console.log("emailInputRef.current.value:", emailInputRef.current?.value)
      if (!emailInputRef.current || !emailInputRef.current?.value) {
        // displayToast("Enter email address")
        return
      }
      // console.log("messageInputRef.current.value:", messageInputRef.current?.value)
      if (!messageInputRef.current || !messageInputRef.current?.value) {
        // displayToast("Write a message")
        return
      }
      const formData: ContactFormData = {
        contactEml: emailInputRef.current.value,
        contactMessage: messageInputRef.current.value
      }
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      })

      const data = await response.json()

      if (response.ok) {
        displayToast("MESSAGE SENT", "green", false)
        setShowAltContact(false)
        // addNewMessage("Message Sent", "Your message was successfully sent.")
      } else {
        displayToast("ELSE-- FAILED TO SEND MESSAGE", "red", true)
        // addNewMessage("Failed to Send Message", "Your message failed to send.")
        console.error('Failed to send')
        //set toast message
      }
    } catch (error) {
      console.error('Submission error:', error);
      // displayToast("FAILED TO SEND MESSAGE.")
      displayToast("11CATCH-ERR FAILED TO SEND", "red", true)
      // addNewMessage("Failed to Send Message", "Your message failed to send.")
      console.log("It is done.")


      // set toast message
    }
  }

  const displayToast = (message: string, color: string, showAlt: boolean) => {
    setToastMessage(message)
    setShowToast(true)
    setToastColor(color)
    if (!showAlt) setShowAltContact(false)

    setTimeout(() => {
      setShowToast(false)
      setToastMessage("")
      if (showAlt) setShowAltContact(true)
    }, 3000)
  }

  return (
    <div className={styles.contactWindow}>
      <form name="contact" method="POST" onSubmit={handleSubmit}>

        {/* Gap created by a div to balance space for toast at the bottom of the form */}
        <div className={styles.topGap}></div>

        {/*
         * EMAIL input:
         * - SASS `::before` pseudo-element provides label to avoid autofill misinterpretations.
         * - "contactEml" obfuscates field from password managers but retains clarity for developers.
         * - `autoComplete='off'` tries to prevent browser autofill, but might be overridden.
         */}
        <div
          className={`${styles.emailSection}  ${styles.section} ${isEmailFocused ? styles.focused : ''}`}
          onClick={handleEmailSectionClick}
        >
          <div className={styles.labelContainer}>
            <label htmlFor="email">EMAIL&#8202;&#8202;:</label>
          </div>
          <input
            type="text"
            className={styles.email}
            name="email"
            autoComplete='off'
            required
            aria-label="E- mail address"
            onFocus={handleEmailFocus}
            onBlur={handleEmailBlur}
            ref={emailInputRef}
          ></input>
        </div>
        <div
          className={`${styles.messageSection} ${styles.section} ${isMessageFocused ? styles.focused : ''}`}
          onClick={handleMessageSectionClick}
        >
          <div className={styles.labelContainer}>
            <label htmlFor="contactMessage">MESSAGE&#8202;&#8202;:</label>
          </div>
          <textarea
            className={styles.contactMessage}
            name="contactMessage"
            onFocus={handleMessageFocus}
            onBlur={handleMessageBlur}
            ref={messageInputRef}
            required
          >
          </textarea>
        </div>

        <div className={styles.bottomRow}>

          {showAltContact &&
            <div className={styles.altContact}>
              <p>If error persists, send an email:<br></br><address><a href="mailto:peter@petergregory.dev">peter@petergregory.dev</a></address></p>
            </div>
          }

          <div className={styles.bottomRight}>
            <div id="toast" className={`${styles.toastSection} ${showToast && styles.show}`}>
              {showToast &&
                <p id="toastMessage" style={{ color: toastColor }}>{toastMessage}</p>
              }
            </div>
            <input type="submit" value="SEND"></input>
          </div>

        </div>
      </form>
    </div>
  )
}

export default ContactWindow;