import styles from './CloseButton.module.sass'


const CloseButton: React.FC = () => {
  return (
      <svg viewBox="0 0 100 100" className={styles.closeButton} vectorEffect='non-scaling-stroke'>
        <line x1="5" y1="95" x2="95" y2="5" fill="none" stroke="#000" strokeMiterlimit="10" vectorEffect='non-scaling-stroke'/>
        <line x1="5" y1="5" x2="95" y2="95" fill="none" stroke="#000" strokeMiterlimit="10" vectorEffect='non-scaling-stroke'/>
      </svg>
  )
}

export default CloseButton