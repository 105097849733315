import { useCallback, useContext, useEffect, useState } from 'react'
import { Modal } from '../../layouts/Modal/Modal'
import { NotificationsIcon } from '../../ui/icons/NotificationsIcon/NotificationsIcon'
import styles from './Notifications.module.sass'
import AppContext from '../../App/AppContext'
import { SingleLineNotification } from './SingleLineNotification/SingleLineNotification'
import { DetailedNotification } from './DetailedNotification/DetailedNotification'
import { Notification } from '../../../types/types'

type NotificationsProps = {
  setNotificationsCentreIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const Notifications: React.FC<NotificationsProps> = ({
  setNotificationsCentreIsOpen
}) => {

  const { notifications, setNotifications } = useContext(AppContext)

  const [showDetails, setShowDetails] = useState<boolean[]>(notifications.map(n => false))

  useEffect(() => {
    setShowDetails(prev => {
      let result = prev
      while (showDetails.length < notifications.length) {
        result.push(false)
      }
      return result
    })
  }, [notifications])


  useEffect(() => { console.log("showDetails:", showDetails) }, [showDetails])

  const toggleShowDetailsAtIndex = useCallback((index: number) => {
    setShowDetails(prev => {
      const prec = prev.slice(0, index)
      const newValue = [!prev.at(index)]
      const succ = prev.slice(index + 1)
      return [...prec, ...newValue, ...succ]
    })
  }, [])

  return (

    <div className={styles.notifications}>
      <Modal onStateChange={setNotificationsCentreIsOpen}>
        <Modal.Toggle>
          <div className={styles.notificationsIcon}>
            <NotificationsIcon />
          </div>
        </Modal.Toggle>
        <Modal.Box>
          <div className={styles.notificationsArea}>
            <div className={`${styles.notificationsCentre} global_box`}>
              <h3>NOTIFICATIONS</h3>


              {notifications.length === 0 ?
                <p>NO NOTIFICATIONS.</p> :

                <div className={styles.listContainer}>
                  {notifications.map((notification, index) =>
                    <>
                      <SingleLineNotification
                        key={index}
                        notification={notification}
                        toggleShowDetails={() => toggleShowDetailsAtIndex(index)}
                      />
                      {/* <div className={styles.details}>
                        <DetailedNotification notification={notifications.at(index) as Notification} />
                      </div> */}
                    </>
                  )}
                </div>
              }
            </div>
            {showDetails.map((show, index) => {
              return show ?
                <div className={styles.details}>
                  <DetailedNotification notification={notifications.at(index) as Notification} />
                </div>
                : null
            })}
          </div>
        </Modal.Box>
      </Modal>
    </div>

  )
}