import { useState, useEffect } from 'react'
import { BoundingArea, LayoutParams } from '../types/types'
import useViewportSize from './useViewportSize';

type UseDraggableProps = {
  windowRef: React.RefObject<HTMLDivElement>
  layoutParams: LayoutParams
  setLayoutParams: React.Dispatch<React.SetStateAction<LayoutParams>>
  boundingElementRef: React.RefObject<HTMLDivElement>
}

const useDraggable = ({
  windowRef,
  layoutParams,
  setLayoutParams,
  boundingElementRef
}: UseDraggableProps) => {

  const { viewportSize: viewportSize } = useViewportSize();
  const [boundingArea, setBoundingArea] = useState<BoundingArea>()

  useEffect(() => {
    if (boundingElementRef.current && windowRef.current) {
      const { height: windowHeight, width: windowWidth } = windowRef.current.getBoundingClientRect()
      const { top: backgroundTop, bottom: backgroundBottom, left: backgroundLeft, right: backgroundRight } = boundingElementRef.current.getBoundingClientRect()

      setBoundingArea({
        minTop: backgroundTop,
        minBottom: backgroundBottom - windowHeight,
        minLeft: backgroundLeft,
        minRight: backgroundRight - windowWidth,
        maxHeight: 0,
        maxWidth: 0
      })
    }
  }, [boundingElementRef, viewportSize, layoutParams])

  const constrainToBoundingArea = (value: number, min: number, max: number) => Math.min(Math.max(value, min), max)

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement>, layoutParams: LayoutParams) => {

    if (!windowRef.current || !layoutParams || !boundingArea || !viewportSize) return

    const initialX = e.clientX - layoutParams.left
    const initialY = e.clientY - layoutParams.top

    const startDragOnMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {

      document.body.style.userSelect = 'none'

      const onMouseMove = (e: MouseEvent) => dragOnMove(e)

      const dragOnMove = (
        moveEvent: MouseEvent,
      ) => {

        let left = moveEvent.clientX - initialX
        let top = moveEvent.clientY - initialY

        left = constrainToBoundingArea(left, boundingArea.minLeft, boundingArea.minRight)
        top = constrainToBoundingArea(top, boundingArea.minTop, boundingArea.minBottom)

        setLayoutParams(prev => ({ ...prev, top, left }))
      }
      const onMouseUp = () => {
        document.removeEventListener('mousemove', onMouseMove)
        document.removeEventListener('mouseup', onMouseUp)
        document.body.style.userSelect = ''
      }
      document.addEventListener('mousemove', onMouseMove)
      document.addEventListener('mouseup', onMouseUp)
    }
    startDragOnMouseDown(e)
  }
  return { startDragging: onMouseDown }
}

export default useDraggable