function ProfileIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Document-Magnifying-Glass"
      viewBox="0 0 128 128"
    >
      <path
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M41.78 29.23l-.34-15.45c-.19-4.1-3.67-7-7.79-6.48-1.93.24-3.75 1.22-5.08 2.73-1.37 1.54-2.11 3.51-2.06 5.48l.52 22.25c.24 2.86 2.76 4.79 5.64 4.33 2.63-.43 4.69-2.73 4.8-5.38l-.49-23.15c-.11-1.68-1.56-2.84-3.25-2.6-1.59.23-2.84 1.63-2.87 3.23v.55h.01l.51 15.9"
      ></path>
      <path
        id="back-page"
        fill="#89d1b4"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M94.5 14.89v39.54c-2.18-2.8-5.17-4.91-8.64-6.03-1.16-.11-2.33-.11-3.55 0-11.14.98-20.18 10.81-20.18 21.95 0 8.43 5.16 15.2 12.5 17.58 1.15.11 2.34.11 3.56 0 6.7-.58 12.64-4.37 16.31-9.72v30.41c0 .49-.4.93-.89.97L19 116.11l-2.61.23a.799.799 0 01-.89-.81V21.8c0-.49.4-.92.89-.97l10.21-.89h.01l4.4-.39h.02l1.35-.12 4.71-.41 4.45-.39 52.07-4.55c.49-.04.89.32.89.81z"
      ></path>
      <path
        id="front-page"
        fill="#96e4c5"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M98 34.19v27.97c-.57-2.91-1.79-5.53-3.5-7.73-2.18-2.8-5.17-4.91-8.64-6.03-1.16-.11-2.33-.11-3.55 0-11.14.98-20.18 10.81-20.18 21.95 0 8.43 5.16 15.2 12.5 17.58 1.15.11 2.34.11 3.56 0 6.7-.58 12.64-4.37 16.31-9.72 1.71-2.49 2.93-5.32 3.5-8.33v41.95c0 .48-.37.89-.85.93l-77.13 6.75a.946.946 0 01-1.02-.94V24.94c0-.48.37-.89.85-.93l6.83-.6h.02l4.43-.39h.01l.92-.08 5.1-.45h.01l4.45-.39 32.95-2.88c.4-.03.8.07 1.15.28L94.5 31.45l3.07 1.95c.27.17.43.47.43.79z"
      ></path>
      <path
        fill="#89d1b4"
        d="M97.5 69.07c0 5.99-2.06 11.68-5.51 16.3a27.964 27.964 0 01-4.29 4.61c-.48.41-.97.81-1.47 1.18l-.02.02c-3.87 2.89-8.49 4.78-13.44 5.22-3.33.3-6.52-.09-9.41-1.05-11.23-1.03-19.85-10-20.34-21.85-.01-.36-.02-.72-.02-1.08 0-13.88 11.07-26.11 24.73-27.33 3.32-.3 6.49.1 9.39 1.06 3.93.35 7.53 1.68 10.58 3.76 1.59 1.08 3.04 2.38 4.29 3.83 3.44 4.01 5.51 9.34 5.51 15.33z"
      ></path>
      <path
        fill="#7dbea4"
        d="M95 69.07c0 5.12-1.79 9.98-4.8 13.92a24.05 24.05 0 01-3.74 3.94c-.42.35-.84.69-1.28 1.01l-.02.02c-3.37 2.47-7.4 4.08-11.71 4.46-2.9.26-5.68-.07-8.2-.89-9.79-.88-17.3-8.54-17.73-18.67-.01-.31-.02-.62-.02-.93 0-11.86 9.65-22.3 21.55-23.35 2.89-.26 5.66.09 8.19.9 3.43.3 6.56 1.44 9.22 3.21 1.39.93 2.65 2.03 3.74 3.28 3 3.42 4.8 7.98 4.8 13.09z"
      ></path>
      <path
        fill="#96e4c5"
        d="M96.5 67.62c0 4.63-1.62 9.03-4.35 12.6-.99 1.31-2.13 2.5-3.38 3.56-.38.32-.76.63-1.16.91l-.02.02c-3.05 2.23-6.7 3.7-10.6 4.03-2.63.23-5.14-.07-7.42-.81-8.86-.8-15.66-7.73-16.05-16.9 0-.28-.02-.56-.02-.84 0-10.74 8.74-20.19 19.51-21.13 2.62-.23 5.12.08 7.41.82 3.1.27 5.94 1.3 8.35 2.91 1.26.84 2.4 1.84 3.38 2.97 2.72 3.1 4.35 7.22 4.35 11.85z"
      ></path>
      <path
        fill="#7dbea4"
        d="M80.08 43.33c-.22-.17-.26-.47-.27-.52-.09-.51-3.08-8.85-7.5-21.05-.07-.07-.45-1.55-.45-1.55l1.62-.16 1.14-.13.16.37 20.27 13.69c.56.36-.48.71.19.71.5 0 .5 2 0 2-.82.4-13.66 6.73-14.21 6.8-.19.02-.4 0-.4 0-.15-.02-.39-.04-.55-.17z"
      ></path>
      <path
        fill="#96e4c5"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M82.8 40.82l13.59-6.23c.59-.27.65-1.09.1-1.44l-21-13.6c-.14-.09-.31.05-.26.2l7.5 21.04s.04.04.06.03z"
      ></path>
      <path
        fill="#fff"
        stroke="#000"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M33.5 7.2c-1.93.17-3.61.99-4.93 2.5-1.37 1.54-2.11 3.51-2.06 5.48l.52 22.25c.24 2.86 2.76 4.79 5.64 4.33 2.63-.43 4.69-2.73 4.8-5.38l-.49-23.15c-.11-1.68-1.56-2.84-3.25-2.6-1.59.23-2.84 1.63-2.87 3.23v.55h.01l.64 19.6"
      ></path>
      <g id="magnifying-glass">
        <path
          id="handle"
          d="M94.33 83.34l19.05 28.33c.5.74.73 1.68.56 2.59-.55 2.91-2.71 2.62-4.04 2.14-.65-.23-1.46-.8-1.87-1.41L89.5 87.46s3.22-1.84 4.83-4.13z"
        ></path>
        <path
          fill="#89d1b4"
          d="M73.96 100.72c.57-.05 1.13-.13 1.68-.23-.55.11-1.11.19-1.67.24h-.01zm6.47-1.7c-.26.13-.51.23-.77.33.26-.11.51-.22.77-.33z"
        ></path>
        <g id="glass">
          <path
            fill="#fff"
            stroke="#000"
            strokeMiterlimit="10"
            strokeWidth="1.5"
            d="M102.5 66.81c0 4.81-1.68 9.38-4.5 13.09-1.02 1.36-2.2 2.6-3.5 3.7-.39.33-.79.65-1.2.95l-.02.02c-3.16 2.32-6.93 3.84-10.97 4.19-2.72.24-5.32-.07-7.68-.84 1.15.11 2.34.11 3.56 0 6.7-.58 12.64-4.37 16.31-9.72 1.71-2.49 2.93-5.32 3.5-8.33.24-1.27.37-2.57.37-3.89s-.13-2.6-.37-3.82c-.57-2.91-1.79-5.53-3.5-7.73-2.18-2.8-5.17-4.91-8.64-6.03 3.21.28 6.15 1.35 8.64 3.02 1.3.87 2.48 1.91 3.5 3.08 2.81 3.22 4.5 7.5 4.5 12.31z"
          ></path>
          <path
            fill="#fff"
            stroke="#000"
            strokeMiterlimit="10"
            strokeWidth="1.5"
            d="M85.86 48.4c-1.16-.11-2.33-.11-3.55 0-11.14.98-20.18 10.81-20.18 21.95 0 8.43 5.16 15.2 12.5 17.58-9.17-.83-16.21-8.03-16.61-17.55-.01-.29-.02-.58-.02-.87 0-11.15 9.04-20.97 20.19-21.95 2.71-.24 5.3.08 7.67.85z"
          ></path>
          <g id="glass_light">
            <path
              fill="#a3eed1"
              stroke="#000"
              strokeMiterlimit="10"
              strokeWidth="1.5"
              d="M98 62.16v7.71c-.57 3.01-1.79 5.84-3.5 8.33-3.67 5.35-9.61 9.14-16.31 9.72-1.22.11-2.41.11-3.56 0-7.34-2.38-12.5-9.15-12.5-17.58 0-11.14 9.04-20.97 20.18-21.95 1.22-.1 2.39-.1 3.55 0 3.47 1.12 6.46 3.23 8.64 6.03 1.71 2.2 2.93 4.82 3.5 7.73z"
            ></path>
            <path
              fill="#c8f8e3"
              d="M74.75 86.19c-.45 0-.9-.02-1.35-.05-6.63-2.25-10.91-8.35-10.91-15.61 0-10.11 8.23-19.07 18.36-19.96.53-.04 1.07-.07 1.58-.07.47 0 .94.02 1.39.06 2.98 1 5.55 2.83 7.47 5.29 1.53 1.97 2.56 4.22 3.08 6.7v7.32c-.52 2.62-1.58 5.11-3.15 7.39-3.46 5.04-9 8.35-14.83 8.86-.55.05-1.1.08-1.63.08z"
            ></path>
            <path
              fill="#e1fdf2"
              d="M72.06 79c-4.29-1.71-6.93-5.74-6.93-10.66 0-7.46 6.32-14.32 13.79-14.98.36-.03.76-.05 1.14-.05h.37c1.8.72 3.36 1.89 4.54 3.41.96 1.23 1.64 2.64 2.02 4.19v6.23a15.06 15.06 0 01-2.27 5.09c-2.62 3.83-6.79 6.33-11.14 6.71-.42.04-.82.06-1.2.06h-.32z"
            ></path>
          </g>
          <path
            id="person-icon"
            fill="none"
            stroke="#000"
            strokeMiterlimit="10"
            strokeWidth="1.5"
            d="M83.9 66.36c2.61 1.06 4.35 3.46 4.6 6.33v2.73l-16.19 1.42v-2.72c.32-4.28 3.86-7.88 8.12-8.26 1.02-.09 2.02-.49 2.86-1.15.22-.17.43-.36.62-.56 1.98-2.1 2.02-5.26.1-7.07-1.92-1.81-5.08-1.58-7.06.52-1.98 2.1-2.02 5.26-.15 7.04"
          ></path>
        </g>
      </g>
      <path
        fill="#96e4c5"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M32.5 58.07l18-1.57m-18 11.07l17-1.48m-17 10.48l19.5-1.7m-19.5 10.7L55 83.61"
      ></path>
      <path
        fill="#89d1b4"
        d="M97.25 104.5v6c0 .18-.06.33-.16.47-.84.19-1.78 0-2.5-.22-.73-.22-1.64-.78-2.11-1.38l-.78-1-12.08-15.58 7.14-1.81 6.55 8.44 3.94 5.07z"
      ></path>
    </svg>
  );
}

export default ProfileIcon;
