import { WindowStates } from '../types/types'

const useWindowManager = (
  windows: WindowStates,
  setWindows: React.Dispatch<React.SetStateAction<WindowStates>>) => {

  const updateWindow = (windowId: string, prop: keyof WindowStates, newValue: any) => {
    setWindows(prevWindows => ({
      ...prevWindows,
      [windowId]: {
        ...prevWindows[windowId],
        [prop]: newValue
      }
    }));
  };

  const getZIndex = (windowId: string) => (windows[windowId].zIndex)
  const openWindow = (windowId: string) => { updateWindow(windowId, 'isOpen', true) }
  const closeWindow = (windowId: string) => { updateWindow(windowId, 'isOpen', false) }
  const isWindowOpen = (windowId: string) => (windows[windowId].isOpen)
  const isWindowMinimised = (windowId: string) => (windows[windowId].isMinimised)
  const isWindowFullScreen = (windowId: string) => (windows[windowId].isFullScreen)
  const isWindowInFront = (windowId: string) => (windows[windowId].isWindowInFront)
  const setFullScreen = (windowId: string, status: boolean) => { updateWindow(windowId, 'isFullScreen', status) }
  const toggleFullScreen = (windowId: string) => { updateWindow(windowId, 'isFullScreen', !windows[windowId].isFullScreen) }
  const setMinimised = (windowId: string, status: boolean) => { updateWindow(windowId, 'isMinimised', status) }

  const bringWindowToFront = (windowId: string) => {

    setWindows((prevWindows) => {

      const openWindows = Object.entries(prevWindows)
        .filter(([id, window]) => window.isOpen && id !== windowId)
        .sort((a, b) => a[1].zIndex - b[1].zIndex)

      const updatedWindows = openWindows.reduce((acc, [id], index) => {
        acc[id] = { ...prevWindows[id], zIndex: index + 1, isWindowInFront: false }
        return acc
      }, {} as WindowStates)

      Object.entries(prevWindows).forEach(([id, window]) => {
        if (!window.isOpen) {
          updatedWindows[id] = { ...window, zIndex: 0, isWindowInFront: false }
        }
      })

      const topZIndex = openWindows.length + 1
      updatedWindows[windowId] = { ...prevWindows[windowId], zIndex: topZIndex, isWindowInFront: true }
      return updatedWindows

    })
  }

  const setFrontWindow = () => {
    setWindows((prevWindows) => {

      const openWindows = Object.entries(prevWindows)
        .filter(([, window]) => window.isOpen)
        .sort((a, b) => a[1].zIndex = b[1].zIndex)

      const frontWindowId = openWindows.at(openWindows.length - 1)?.[0]

      const updatedWindows = Object.entries(prevWindows)
        .map(([windowId, window]) => [windowId, { ...window, inMobileView: windowId == frontWindowId }])

      return Object.fromEntries(updatedWindows)
    })
  }

  return {
    getZIndex,
    openWindow,
    closeWindow,
    isWindowOpen,
    isWindowInFront,
    bringWindowToFront,
    isWindowMinimised,
    setMinimised,
    isWindowFullScreen,
    setFullScreen,
    toggleFullScreen,
    setFrontWindow
  }
}

export default useWindowManager