import styles from './BouncingBall.module.sass'

type BouncingBallProps = {
}

export const BouncingBall: React.FC<BouncingBallProps> = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <ellipse className={styles.spinner_rXNP} cx="12" cy="5" rx="4" ry="4" />
    </svg>)

    
}


