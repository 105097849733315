const CircleEmpty = () => {
  return (
    <svg id="Icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <circle
        cx='50'
        cy='50'
        r='25'
        fill='none'
      />
    </svg>
  )
}

export default CircleEmpty