import { useEffect, useRef, useState } from 'react'
import styles from './ToolTip.module.sass'

type ToolTipProps = {
  text: string
  position: "top" | "bottom",
  marginTopPx?: number
  marginBottomPx?: number
  marginLeftPx?: number
  marginRightPx?: number
  notifyParentOfActivation?: () => void
  delay?: number | null
  keepHidden?: boolean
}

export const ToolTip: React.FC<ToolTipProps> = ({
  text,
  position,
  marginTopPx: marginTop = 0,
  marginBottomPx: marginBottom = 0,
  marginLeftPx: marginLeft = 0,
  marginRightPx: marginRight = 0,
  notifyParentOfActivation,
  delay,
  keepHidden = false,
  children
}) => {

  const [isActive, setIsActive] = useState(false)
  const timerRef = useRef<NodeJS.Timeout | null>(null)
  const defaultDelay = 1000

  const startTimer = () => {
    if (timerRef.current) clearTimeout(timerRef.current)
    timerRef.current = setTimeout(() => {
      setIsActive(true)
      notifyParentOfActivation?.()
    }, delay ?? defaultDelay)
  }

  const cancelTimer = () => {
    setIsActive(false)
    if (timerRef.current) {
      clearTimeout(timerRef.current)
      timerRef.current = null
    }
  }

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
        timerRef.current = null
      }
    };
  }, []);

  useEffect(() => {
    cancelTimer()
  }, [keepHidden])

  return (

    // keepHidden ?
    //   <>
    //     {children}
    //   </>
    //    :

    <div className={`${styles.toolTipWrapper}`}
      onMouseEnter={startTimer}
      onMouseLeave={cancelTimer}
      onFocus={startTimer}
      onBlur={cancelTimer}
      role="tooltip">
      {children}
      <div className={`${styles.toolTipBox} ${isActive ? styles.active : ''} ${styles[position]} ${keepHidden ? styles.hidden : ""}`} style={{
        marginTop: `${marginTop}px`,
        marginRight: `${marginRight}px`,
        marginBottom: `${marginBottom}px`,
        marginLeft: `${marginLeft}px`
        ,
        ...(position === "top" ? { bottom: "100%" } : {}),
        ...(position === "bottom" ? { top: "100%" } : {})
      }}>
        <h4>{text}</h4>
      </div>
    </div >
  )
}