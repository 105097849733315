import { useContext, useState } from 'react'
import styles from './DockVoid.module.sass'
import AppContext from '../../App/AppContext'

type DockVoidProps = {
}

export const DockVoid: React.FC<DockVoidProps> = () => {

  const { setDockHidden } = useContext(AppContext)

  const [transitioning, setTransitioning] = useState(true)

const enableRestoreDock = () => {
  setTransitioning(false)
}

  const restoreDock = () => {
    setDockHidden(false)
  }

  return (
    <div
      className={`${styles.dockVoid} ${!transitioning && styles.active}`}
      onMouseOut={transitioning? enableRestoreDock: undefined}
      onMouseOver={!transitioning? restoreDock: undefined}
    ></div>
  )
}