import React, { useState, createContext, useContext, ReactNode, FunctionComponent, MouseEvent, useEffect } from 'react';
import styles from "./Modal.module.sass"

interface ModalProps {
  onStateChange?: React.Dispatch<React.SetStateAction<boolean>>;
  children: ReactNode;
}

interface ModalBoxProps {
  children: ReactNode;
}

interface ModalToggleProps {
  children: ReactNode;
}

interface ModalContextType {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  toggle: (e: MouseEvent) => void
}

const defaultModalContextValue: ModalContextType = {
  isOpen: false,
  setIsOpen: () => { },
  toggle: () => { }
};

const ModalContext = createContext<ModalContextType>(defaultModalContextValue);

export const Modal: FunctionComponent<ModalProps> & {
  Box: FunctionComponent<ModalBoxProps>;
  Toggle: FunctionComponent<ModalToggleProps>;
} = ({ children, onStateChange }) => {

  const [isOpen, setIsOpen] = useState(false);

  const toggle = (e: MouseEvent) => {
    console.log("!!!!TOGGLE CALLED")
    console.log("e.target:", e.target)
    console.log("inside toggle -- isOpen:", isOpen)
    setIsOpen(prev => !prev)
    if (onStateChange) {
      onStateChange(!isOpen)
    }
  }

  useEffect(() => {
    console.log("isOpen:", isOpen)
  }, [isOpen])

  return (
    <ModalContext.Provider value={{ isOpen, setIsOpen, toggle }}>
      {children}
    </ModalContext.Provider>
  );
};

const Box: FunctionComponent<ModalBoxProps> = ({ children }) => {
  const { isOpen } = useContext(ModalContext);
  console.log("Modal Box isOpen:", isOpen);
  if (!isOpen) return null;
  return <>{children}</>;
};

const Toggle: FunctionComponent<ModalToggleProps> = ({ children }) => {
  const { toggle } = useContext(ModalContext);
  return (
    <div className={styles.toggle} onClick={e => toggle(e)}>
      {children}
    </div>
  );
};

Modal.Box = Box;
Modal.Toggle = Toggle;
