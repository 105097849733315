import WindowTemplate, { WindowTemplateProps } from "../WindowTemplate"

export const Window = (props: WindowTemplateProps & { content: JSX.Element }) => {

  return (
    <>
      <WindowTemplate {...props}>
        {props.content}
      </WindowTemplate>
    </>
  )
}