import { useEffect, useMemo, useRef, useState } from "react";
import { Size } from "../types/types";

const useViewportSize = () => {
  const [viewportSize, setViewportSize] = useState<Size>({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  const initialSizeRef = useRef<Size>(viewportSize);

  useEffect(() => {
    const viewportSizeHandler = () => {
      setViewportSize({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    };
    window.addEventListener("resize", viewportSizeHandler);

    return () => {
      window.removeEventListener("resize", viewportSizeHandler);
    };
  }, []);

  const viewportSizeChange = useMemo(() => {
    const widthChange = viewportSize.width - initialSizeRef.current.width;
    const heightChange = viewportSize.height - initialSizeRef.current.height;
    return {
      width: widthChange,
      height: heightChange
    };
  }, [viewportSize]);

  const isMobile = useMemo(() => viewportSize.width < 481 || viewportSize.height < 481, [viewportSize])

  return { viewportSize, isMobile, viewportSizeChange };
};

export default useViewportSize;