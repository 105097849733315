import styles from './ContactDockIcon.module.sass'

type ContactDockIconProps = {
}

export const ContactDockIcon:React.FC<ContactDockIconProps> = () => {
  return(
<svg xmlns="http://www.w3.org/2000/svg" viewBox="7 6 86 86">
  <rect width="55" height="40" x="22.73" y="30.24" fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="4.38" rx="5.1" ry="5.1"/>
  <path fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="4.38" d="m24.25 67.94 20.5-16.52M75.75 67.94l-20.5-16.52M24.97 31.12l23.65 23.99c.61.62 1.62.63 2.24.02l24.63-24.02"/>
</svg>
  )
}