import styles from './Dock.module.sass'
import useWindowManager from "../../hooks/useWindowManager";
import { useContext, useState } from "react";
import AppContext from "../App/AppContext";
import { initialWindowConfigMap } from "../../config/initialWindowConfig";
import { DockBackground } from '../ui/DockBackground/DockBackground';
import { DockOverlayTop } from '../ui/dockOverlays/DockOverlayTop';
import { DockOverlayLeft } from '../ui/dockOverlays/DockOverlayLeft';
import { DockOverlayBottom } from '../ui/dockOverlays/DockOverlayBottom';
import { DockOverlayRight } from '../ui/dockOverlays/DockOverlayRight';
import { RestartDockIcon } from '../ui/dockIcons/RestartDockIcon/RestartDockIcon';
import { HideDockIcon } from '../ui/dockIcons/HideDockIcon/HideDockIcon';
import { ToolTip } from '../ui/ToolTip/ToolTip';

export const Dock: React.FC = () => {

  const [activeDock, setActiveDock] = useState(false)
  const toolTipDelay = activeDock ? 200 : null
  const { windows, setWindows, setDockHidden, setFirstVisit } = useContext(AppContext);

  const {
    openWindow,
    isWindowOpen,
    setMinimised,
    bringWindowToFront
  } = useWindowManager(windows, setWindows)

  const clickAppIcon = (windowId: string) => {
    openWindow(windowId)
    setMinimised(windowId, false)
    bringWindowToFront(windowId)
  }

  const hideButtonClicked = () => {
    setDockHidden(true)
  }

  const handleEndUserInteraction = () => {
    setActiveDock(false)
  }

  return (

    <div className={styles.dock} onMouseLeave={handleEndUserInteraction} onBlur={handleEndUserInteraction}>
      <div className={styles.backgroundBox}>
        <DockBackground />
        <div className={styles.dockIcons}>
          <div className={styles.leftIcons}>
            <div className={styles.dockIconBoxShadow}>
              <ToolTip text="Restart" position="top" marginBottomPx={8} notifyParentOfActivation={() => setActiveDock(true)} delay={toolTipDelay}>
                <div className={styles.dockIcon} onClick={() => {
                  setFirstVisit(true)
                }}>
                  <RestartDockIcon />
                </div>
              </ToolTip>
            </div>

            <div className={styles.dockIconBoxShadow}>
              <ToolTip text="Hide Dock" position="top" marginBottomPx={8} notifyParentOfActivation={() => setActiveDock(true)} delay={toolTipDelay}>
                <div className={styles.dockIcon} onClick={hideButtonClicked}>
                  <HideDockIcon />
                </div>
              </ToolTip>
            </div>
          </div>

          <div className={styles.centerIcons}>
            <div className={styles.dockIconBoxShadow}>
              <ToolTip text="Apps" position="top" marginBottomPx={8} notifyParentOfActivation={() => setActiveDock(true)} delay={toolTipDelay}>
                <div className={styles.dockIcon} onClick={e => clickAppIcon('apps')}>
                  {initialWindowConfigMap['apps'].dockIcon}
                </div>
              </ToolTip>
            </div>

            {Object.entries(windows).map(([windowId, windowValues]) => (
              windowId != 'apps' && windowValues.isOpen &&
              <div className={styles.dockIconBoxShadow}>
                <ToolTip text={initialWindowConfigMap[windowId].title} position="top" marginBottomPx={8} notifyParentOfActivation={() => setActiveDock(true)} delay={toolTipDelay}>
                  <div className={styles.dockIcon} onClick={e => clickAppIcon(windowId)}>
                    {initialWindowConfigMap[windowId].dockIcon}
                  </div>
                </ToolTip>
              </div>

            ))}
          </div>
        </div>
        <div className={styles.dockOverlay}>
          <DockOverlayTop />
          <DockOverlayBottom />
          <DockOverlayLeft />
          <DockOverlayRight />
        </div>
      </div>
    </div >
  )
}

export default Dock;

function setMinimised(windowId: string, arg1: boolean) {
  throw new Error('Function not implemented.');
}
