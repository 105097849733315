import styles from './TabContent.module.sass'

export type TabContentProps = {
  title: string;
  children?: React.ReactNode;
}

export const TabContent: React.FC<TabContentProps> = ({
  title,
  children
}) => {

  return (
    <div className={styles.background}>
      <div className={styles.contentContainer}>
        {children}
      </div>
    </div>
  )
}

export default TabContent