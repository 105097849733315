import styles from './NotificationsIcon.module.sass'

type NotificationsIconProps = {
}

export const NotificationsIcon: React.FC<NotificationsIconProps> = () => {
  return (
    <svg id="notifications" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 101">
      <line x1="37.5" y1="65" x2="79.5" y2="65" fill="none" stroke="white" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.2px" vectorEffect="non-scaling-stroke"/>
      <rect x="20.5" y="61.5" width="8" height="8" rx="1" ry="1" fill="white" stroke="white" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="0.2px" vectorEffect="non-scaling-stroke"/>
      <line x1="37.5" y1="50" x2="79.5" y2="50" fill="none" stroke="white" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.2px" vectorEffect="non-scaling-stroke"/>
      <rect x="20.5" y="46.5" width="8" height="8" rx="1" ry="1" fill="white" stroke="white" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="0.2px" vectorEffect="non-scaling-stroke"/>
      <line x1="37.5" y1="36" x2="79.5" y2="36" fill="none" stroke="white" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.2px" vectorEffect="non-scaling-stroke"/>
      <rect x="20.5" y="31.5" width="8" height="8" rx="1" ry="1" fill="white" stroke="white" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="0.2px" vectorEffect="non-scaling-stroke"/>
    </svg>
  )
}