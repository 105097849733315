const ArrowFlatRight: React.FC = () => {
  return (
    <svg id="Icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <path
        vectorEffect="non-scaling-stroke"
        d="M30,94 l25,-44 l-25,-44"
        fill='none'
      />
    </svg>
  )
}

export default ArrowFlatRight