import { ProfileTab } from "../ProfileTab/ProfileTab"

const FrontEndTab: React.FC = () => {
  return (
    <ProfileTab >
      <div>
        <p>Blending art with algorithms, I bridge design and functionality with a rich background in front-end development.</p>

        <ul>
          <li>
            Core Web Technologies & Standards: HTML, CSS, W3C and WAI-ARIA specifications, JavaScript
          </li>
          <li>
            Javascript/Node Application Frameworks: experience with React, Vue and Angular
          </li>
          <li>
            CSS & SASS: organising and maintaining structured stylesheets within a coherent design system
          </li>
          <li>
            Animation: SVG, Lottie, Greensock (GSAP)
          </li>
          <li>
            Unit & Integration Testing: Mocha, Chai, Jest,
          </li>
          <li>
            UI Testing across browsers, devices and operating systems: Selenium WebDriver, Cypress.
          </li>
          <li>
            Performance Testing & Optimisation: Ensuring software reliability and performance. DevTools
          </li>
          <li>
            User Testing: Ensuring software reliability and performance. DevTools
          </li>
        </ul>

        <ul>
          <li>
            Core Web Technologies & Standards: HTML, CSS, W3C and WAI-ARIA specifications, JavaScript
          </li>
          <li>
            Javascript/Node Application Frameworks: experience with React, Vue and Angular
          </li>
          <li>
            CSS & SASS: organising and maintaining structured stylesheets within a coherent design system
          </li>
          <li>
            Animation: SVG, Lottie, Greensock (GSAP)
          </li>
          <li>
            Unit & Integration Testing: Mocha, Chai, Jest,
          </li>
          <li>
            UI Testing across browsers, devices and operating systems: Selenium WebDriver, Cypress.
          </li>
          <li>
            Performance Testing & Optimisation: Ensuring software reliability and performance. DevTools
          </li>
          <li>
            User Testing: Ensuring software reliability and performance. DevTools
          </li>

        </ul>


      </div>
    </ProfileTab>
  )
}

export default FrontEndTab
