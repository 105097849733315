import styles from './HideDockIcon.module.sass'

type HideDockIconProps = {
}

export const HideDockIcon: React.FC<HideDockIconProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="hide-dock-v2" viewBox="0 0 100 100">
      <rect width="50.23" height="60.47" x="24.88" y="19.71" fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="5" rx="10.57" ry="10.57" />
      <path fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="5" d="m41.33 56.5 8.41 9.09 8.91-9.09-8.91 9.09.25-31.29v-.01.01" />
    </svg>
  )
}