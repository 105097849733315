import { ProfileTab } from "../ProfileTab/ProfileTab"

const BackEndTab: React.FC = () => {
  return (
    <ProfileTab>
      <div>
        <p></p>
        <ul>
          <li>
            Node.js
          </li>
          <li>
            Python, especially in a scientific context: numPy, pandas, numerical analysis
          </li>
          <li>
            Java. JavaFX,
          </li>
          <li>
            Data: DB management, SQL, MongoDB, data mining and warehousing.
          </li>
          <li>
            Testing: Ensuring software reliability and performance.
          </li>
        </ul>
      </div>
    </ProfileTab>
  )
}

export default BackEndTab


