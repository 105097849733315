const ArrowLeft: React.FC = () => {
  return (
    <svg id="Icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" vector-effect="non-scaling-stroke">
      <line
        x1="5" y1="50" x2="95" y2="50"
        stroke='black'
        stroke-width='4px' />
      <path
        d="M25,35 l-20,15 l20,15"
        fill='none'
        stroke='black'
        stroke-width='4px'
      />
    </svg>
  )
}

export default ArrowLeft