type ExitFullScreenIconProps = {
}

export const ExitFullScreenIcon:React.FC<ExitFullScreenIconProps> = () => {
  return(
    <svg viewBox="0 0 100 100">
      <rect x="20" y="5" width="70" height="70" vectorEffect='non-scaling-stroke' fill="none" stroke="#000"/>
      <rect x="10" y="40" width="50" height="50" fill="white" vectorEffect='non-scaling-stroke' stroke="#000"/>
    </svg>
  )
}