import { PageLayout } from '../../../../layouts/PageLayout/PageLayout'
import styles from './ProfileTab.module.sass'

type ProfileTabProps = {
  children: React.ReactNode
}

export const ProfileTab: React.FC<ProfileTabProps> = ({ children }) => {
  return (
    <PageLayout>
      <div className={styles.profileTab}>
        {children}
      </div>
    </PageLayout>
  )
}