export enum LibraryView {
  Collection = "collectionView",
  Book = "bookView"
}

export type CollectionView = {
  viewType: LibraryView.Collection
}

export type BookView = {
  viewType: LibraryView.Book;
  bookId: string; 
}

export type LibraryState = CollectionView | BookView;

export type imageKeyUrlMap = Map<imageKeyString, imageUrlString>

export type imageKeyString = string

export type imageUrlString = string