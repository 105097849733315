import styles from './MinimiseButton.module.sass'


const MinimiseButton: React.FC = () => {
  return (
      <svg viewBox="0 0 100 100" className={styles.minimiseButton}>
        <line x1="5" y1="50" x2="95" y2="50" vectorEffect='non-scaling-stroke' stroke="#000"/>
        {/* <line x1="30" y1="30" x2="70" y2="70" /> */}
      </svg>
  )
}

export default MinimiseButton