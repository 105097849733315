import styles from './LibraryDockIcon.module.sass'

type LibraryDockIconProps = {
}

export const LibraryDockIcon:React.FC<LibraryDockIconProps> = () => {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-2 5 100 100">
  <path fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="4.15" d="M46.74 66.8h6.17H17c-.81 0-1.62.36-2.26 1.14-.48.58-.89 1.4-.89 2.47v11.82h-2.13H82.7M64.69 60.06H30.85l-12.15.03"/>
  <path fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="4.15" d="M52.91 48.02h24.84-3.26V66.8h-4.22"/>
  <path fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="4.15" d="M77.75 48.02c3.63 0 4.06 4.58 4.06 4.58v6.21c0 2.21-.45 4.41-1.39 6.05-.6 1.06-1.42 1.93-2.45 1.93H52.92M50.78 48.02H46.2V31.24h8.99v8.39H78.7"/>
  <path fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="4.15" d="M70.27 48.02h11.54c2.88 0 2.92-4.25 2.92-4.25v-7.56c.04-4.18-3.13-4.98-3.13-4.98H50.79M52.91 48.02h17.36M46.74 48.02h6.17M14.51 31.24H46.2v8.41l-23.47-.02 23.47.02v8.37H13.93h32.81M55.19 39.63v8.39M46.74 66.8v15.44M55.29 74.52h9.99M73.96 82.24V66.8h9.99M81.8 82.24V66.8M16.53 48.02V31.24M64.72 54.03H30.88l-12.16.03"/>
</svg>
  )
}