import styles from './ProfileWindow.module.sass'
import TabLayout from '../../../layouts/TabLayout/TabLayout'
import { Tab } from '../../../layouts/TabLayout/Tab/Tab'
import ProfileSidePanel from './ProfileSidePanel/ProfileSidePanel'
import FrontEndTab from './content/FrontEndTab'
import UxUiTab from './content/UxUiTab'
import BackEndTab from './content/BackEndTab'
import DevOps from './content/DevOpsTab'
import ProjectManagementTab from './content/ProjectManagementTab'


const ProfileWindow = () => {
  return (
    <div className={styles.profileWindow}>

      {/* <div className={styles.leftSidePanel}>
        <ProfileSidePanel />
      </div> */}

      <div className={styles.rightTabPanel}>
        <TabLayout>

          <Tab title="Front End">
            <FrontEndTab />
          </Tab>

          <Tab title='UX/UX Design'>
            <UxUiTab />
          </Tab>

          <Tab title="Back End">
            <BackEndTab />
          </Tab>

          <Tab title="DevOps">
            <DevOps />
          </Tab>

          <Tab title="Proj Management">
            <ProjectManagementTab />
          </Tab>

        </TabLayout>
      </div>

    </div>


  )
}

export default ProfileWindow