import { useCallback, useEffect, useState } from 'react'
import { InitialWindowConfigMap, InitialisedWindowValues, InitialisedWindowValuesIndex, LayoutParams, Size } from '../types/types'

const useCalculateWindowPositions = (
  boundingElementRef: React.RefObject<HTMLDivElement>
) => {

  const [boundingSize, setBoundingSize] = useState<Size>()

  const updateBoundingSize = () => {
    if (boundingElementRef.current) {
      const { width: backgroundWidth, height: backgroundHeight } = boundingElementRef.current.getBoundingClientRect()

      setBoundingSize({
        width: backgroundWidth,
        height: backgroundHeight
      })
    }
  }

  useEffect(() => {
    updateBoundingSize()
  }, [])

  const calculateWindowLayouts =
    useCallback(
      (initialWindowConfigIndex: InitialWindowConfigMap) => {

        let initialisedWindowValuesIndex: InitialisedWindowValuesIndex = {}
        const percentageRegex = /^\d+(\.\d+)?%$/

        if (boundingSize) {

          Object.entries(initialWindowConfigIndex).forEach(([windowId, windowConfig]) => {
            if (windowConfig.initialLayoutParams.top && windowConfig.initialLayoutParams.left) {

              let topValue = 0
              if (typeof windowConfig.initialLayoutParams.top == 'string') {
                if (percentageRegex.test(windowConfig.initialLayoutParams.top)) {
                  topValue = Number(windowConfig.initialLayoutParams.top.replace('%', '')) / 100 * window.innerHeight
                } else {
                  console.error("Missing initialLayoutParams values in initialWindowConfigIndex for window:" + windowId)
                }
              } else {
                topValue = windowConfig.initialLayoutParams.top
              }
              let leftValue = 0
              if (typeof windowConfig.initialLayoutParams.left == 'string') {
                if (percentageRegex.test(windowConfig.initialLayoutParams.left)) {
                  leftValue = Number(windowConfig.initialLayoutParams.left.replace('%', '')) / 100 * window.innerWidth
                } else {
                  console.error("Missing initialLayoutParams values in initialWindowConfigIndex for window:" + windowId)
                }
              } else {
                leftValue = windowConfig.initialLayoutParams.left
              }

              const windowTop = topValue
              const windowLeft = leftValue
              const initialisedWindowValues: InitialisedWindowValues = {
                ...windowConfig,
                layoutParams: {
                  ...windowConfig.initialLayoutParams as LayoutParams,
                  top: windowTop,
                  left: windowLeft

                }
              }
              initialisedWindowValuesIndex[windowId] = initialisedWindowValues
            } else {
              console.error("Missing initialLayoutParams values in initialWindowConfigIndex for window:" + windowId)
            }
          })
        }
        return initialisedWindowValuesIndex
      }, [boundingSize])
  return { calculateWindowLayouts }
}

export default useCalculateWindowPositions
