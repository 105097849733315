import styles from './DockOverlayBottom.module.sass'

type DockOverlayBottomProps = {
}

export const DockOverlayBottom:React.FC<DockOverlayBottomProps> = () => {
  return(

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
          <rect width="100%" height="100%" x="0" y="0" fill="rgb(150,150,150)" stroke="rgb(150,150,150)" stroke-width="1" opacity="1"/>
        </svg>
  )
}