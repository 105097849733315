import { useState } from 'react'
import styles from './InfoButtonModal.module.sass'
import InfoButtonIcon from '../../../../../ui/InfoButtonIcon/InfoButtonIcon'

type InfoButtonModalProps = {
  setShowInfoBox: React.Dispatch<React.SetStateAction<boolean>>
}

export const InfoButtonModal: React.FC<InfoButtonModalProps> = ({
  setShowInfoBox
}) => {

  const [filterHoverEffect, setFilterHoverEffect] = useState<boolean>(false)


  return (

      <div className={`${styles.infoIconContainer} ${styles.button}`}
        onClick={e => setShowInfoBox(prev => !prev)}
        onMouseEnter={e => setFilterHoverEffect(true)}
        onMouseLeave={e => setFilterHoverEffect(false)}>

        <InfoButtonIcon filter={filterHoverEffect} />
      </div>


  )
}