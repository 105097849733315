// import styles from './Tab.module.sass'
import { TabContentProps } from "../TabContent/TabContent"

export const Tab:React.FC<TabContentProps> = ({
  title,
  children
}) => {
  return(
    <></>
  )
}