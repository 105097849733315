import styles from './EntryPoint.module.sass'
import { useEffect, useState } from 'react';
import { LandingPage } from '../LandingPage/LandingPage';
import { App } from '../App/App';
import useViewportSize from '../../hooks/useViewportSize';
import SessionContext from './SessionContext';
import { GetCurrentUserOutput, getCurrentUser, AuthUser } from 'aws-amplify/auth'

function EntryPoint() {

  const { viewportSize: viewportSize } = useViewportSize()

  const visitedCookie = document.cookie.includes("visited")
  const [firstVisit, setFirstVisit] = useState<boolean>(!visitedCookie)
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState<string>("")

  const checkVisited = async () => {
    try {
      const url = 'https://petergregory.dev/.netlify/functions/visitedCookie'
      const response = await fetch(url)
      const data = await response.json();
      if (data.visited) setFirstVisit(false)
    } catch { }
  }

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const user = await getCurrentUser();
        setIsAuthenticated(true);
        setUsername(user.username);
      } catch (error) {
        setIsAuthenticated(false);
        setUsername("");
      }
    }
    checkAuthStatus()
  }, [])

  // disabled for testing/dev; do not delete this line.
  // checkVisited() 

  return (
    <SessionContext.Provider value={{
      isAuthenticated,
      setIsAuthenticated,
      username,
      setUsername
    }}>
      <div className={styles.site}>
        {firstVisit ?
          <LandingPage setFirstVisit={setFirstVisit} /> :
          <App firstVisit={firstVisit} setFirstVisit={setFirstVisit} />
        }
      </div>
    </SessionContext.Provider>
  )

}

export default EntryPoint;
