import { useContext, useEffect, useState } from 'react'
import { Modal } from '../../layouts/Modal/Modal'
import { AuthenticationIcon } from '../../ui/icons/AuthenticationIcon/AuthenticationIcon'
import styles from './Authentication.module.sass'
import { confirmSignIn, ConfirmSignInInput, getCurrentUser, signIn, signOut } from 'aws-amplify/auth'
import SessionContext from '../../EntryPoint/SessionContext'

type AuthenticationProps = {
  setAuthenticationIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

interface LoginInputs {
  username: string;
  password: string;
}

export const Authentication: React.FC<AuthenticationProps> = ({
  setAuthenticationIsOpen
}) => {


  const {
    isAuthenticated,
    setIsAuthenticated,
    username,
    setUsername
  } = useContext(SessionContext)

  const [errorMessage, setErrorMessage] = useState('');

  const [loginInputs, setLoginInputs] = useState<LoginInputs>({
    username: '',
    password: ''
  })

  const [authStep, setAuthStep] = useState<'SIGN_IN' | 'NEW_PASSWORD_REQUIRED' | 'SIGNED_IN'>('SIGN_IN');

  useEffect(() => {
    console.log('Auth Step changed to:', authStep);
  }, [authStep]);

  const [newPassword, setNewPassword] = useState(['', '']);

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    console.log('username is:', loginInputs.username)
    try {
      const output = await signIn(loginInputs)
      const nextStep = output.nextStep.signInStep
      setAuthStep(
        nextStep === "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED" ?
          'NEW_PASSWORD_REQUIRED' :
          nextStep === "DONE" ?
            'SIGNED_IN' :
            'SIGN_IN'
      )
      setIsAuthenticated(output.isSignedIn)
      try {
        const currentUser = await getCurrentUser()
        setUsername(currentUser.username)
      } catch (err) {
        console.error('Error getting current user:', err)
      }
      console.log('Sign-in successful:', output)
    } catch (err) {
      console.error('Error signing in:', err)

      if (err instanceof Error) {
        setErrorMessage(err.message);
      } else if (typeof err === 'string') {
        setErrorMessage(err);
      } else if (typeof err === 'object' && err !== null && 'message' in err) {
        setErrorMessage((err as { message: string }).message);
      } else {
        setErrorMessage('An unknown error occurred during sign-in.');
      }
    }
  }

  const handleNewPassword = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (newPassword[0] != newPassword[1]) {
      // set message passwords do not match
      setErrorMessage('Passwords do not match.');
    } else {
      setErrorMessage('');
      try {
        const confirmSignInInput: ConfirmSignInInput = {
          challengeResponse: newPassword[0]
        }
        const confirmSignInOutput = await confirmSignIn(confirmSignInInput)
        if (confirmSignInOutput.nextStep.signInStep === 'DONE') {
          setAuthStep("SIGNED_IN")
          setIsAuthenticated(true)
          try {
            const currentUser = await getCurrentUser()
            setUsername(currentUser.username)
          } catch (err) {
            console.error('Error getting current user:', err)
          }
        }
      } catch (err) {
        console.error('Error setting new password:', err);
      }
    }

  }

  const handleSignOut = async () => {
    try {
      await signOut()
      setIsAuthenticated(false)
      setUsername("")
    } catch (err) {
      console.error("Error signing out", err)
    }
  }

  return (


    <Modal onStateChange={setAuthenticationIsOpen}>

      <Modal.Toggle >
        <div className={styles.authenticationIcon}>
          <AuthenticationIcon />
        </div>
      </Modal.Toggle>

      <Modal.Box>
        <div className={`${styles.loginContainer} global_box`}>
          {isAuthenticated ? (
            <>
              <h3>Signed in as {username}</h3>
              <button onClick={handleSignOut}>Sign Out</button>
            </>
          ) : authStep === 'NEW_PASSWORD_REQUIRED' ? (
            <form onSubmit={handleNewPassword}>
              <div className={`${styles.username} ${styles.formRow}`}>
                <label htmlFor="newPassword">New Password:</label>
                <input
                  type="password"
                  id="newPassword"
                  value={newPassword[0]}
                  onChange={(e) => {
                    setNewPassword(prev => [e.target.value, prev[1]])
                    setErrorMessage("")
                  }}
                />
              </div>
              <div className={`${styles.password} ${styles.formRow}`}>
                <label htmlFor="confirmNewPassword">Confirm New Password:</label>
                <input
                  type="password"
                  id="confirmNewPassword"
                  value={newPassword[1]}
                  onChange={(e) => {
                    setNewPassword(prev => [prev[0], e.target.value])
                    setErrorMessage("")
                  }}
                />
              </div>
              <div className={styles.submit}>
                <input type="submit" value="SET NEW PASSWORD" />
              </div>
            </form>
          ) : (
            <form onSubmit={handleLogin}>
              <div className={`${styles.username} ${styles.formRow}`}>
                <label htmlFor="username">Username:</label>
                <input
                  type="text"
                  id="username"
                  value={loginInputs.username}
                  onChange={(e) => {
                    setLoginInputs(prev => ({ ...prev, username: e.target.value }))
                    setErrorMessage("")
                  }}
                />
              </div>
              <div className={`${styles.password} ${styles.formRow}`}>
                <label htmlFor="password">Password:</label>
                <input
                  type="password"
                  id="password"
                  value={loginInputs.password}
                  onChange={(e) => {
                    setLoginInputs(prev => ({ ...prev, password: e.target.value }))
                    setErrorMessage("")
                }}
                />
              </div>
              <div className={styles.submit}>
                <input type="submit" value="LOG IN" />
              </div>
            </form>
          )}

          <div className={styles.errorMessage}>
            {errorMessage ? (
              <div className={styles.errorBackground}>
                <p className="error">{errorMessage}</p>
              </div>
            ) : null}
          </div>

        </div>
      </Modal.Box>

    </Modal>

  )
}