import { createContext } from 'react';

type SessionContextType = {
  isAuthenticated: boolean;
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>> 
  username: string;
  setUsername: React.Dispatch<React.SetStateAction<string>> 
}

const defaultContextValue: SessionContextType = {
  isAuthenticated: false,
  setIsAuthenticated: () => {},
  username: "",
  setUsername: () => {}
};

const SessionContext = createContext<SessionContextType>(defaultContextValue);

export default SessionContext;