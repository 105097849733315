import SquareBox from '../icons/SquareBox/SquareBox'
import styles from './CheckBox.module.sass'

type CheckBoxProps = {
  selected: boolean
}

export const CheckBox: React.FC<CheckBoxProps> = ({
  selected
}) => {

  const checkClass = () => (selected ? styles.checked : styles.unchecked)

  return (
    <div className={`${styles.checkBox} ${checkClass()}`} >  
      <SquareBox />
    </div >
  )
}