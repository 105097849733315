import styles from './PG.module.sass'

export const PG: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 138.33 101.73">
      <path d="M4.24 4.24H134.1V97.1H4.24z" className={styles.whiteFill} />
      <path d="M42.77 29.28H30.44v18.5h12.33c5.78 0 9.63-3.85 9.63-9.25s-3.85-9.25-9.63-9.25Zm.77 30.44h-13.1v25.43h-13.1V17.72h26.2c13.87 0 21.96 9.63 21.96 20.81s-8.09 21.19-21.96 21.19ZM114.44 77.84c-5.39 5.78-11.95 7.71-18.88 7.71-7.71 0-13.49-2.7-18.5-7.32-6.55-6.94-6.55-15.41-6.55-26.97s0-20.04 6.55-26.97c5.01-4.62 10.79-7.32 18.5-7.32 15.41 0 23.51 10.4 25.05 21.58h-13.1c-1.54-6.17-5.39-9.63-11.95-9.63-3.85 0-6.55 1.16-8.48 3.47-2.7 2.7-3.47 6.17-3.47 18.88s.77 16.18 3.47 18.88c1.93 2.31 4.62 3.47 8.48 3.47s6.94-1.16 9.25-3.47c2.31-2.7 3.08-5.78 3.08-9.25v-2.31H95.56V47.42h25.43v10.02c0 9.63-1.93 15.41-6.55 20.42Z" />
      <path d="M134.1 4.24V97.1H4.24V4.24H134.1ZM136.03 0H0v101.73h138.33V0h-2.31Z" />
    </svg>
  )
}