import styles from './RestartDockIcon.module.sass'

type RestartDockIconProps = {
}

export const RestartDockIcon: React.FC<RestartDockIconProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="restart" viewBox="2 2 90 90">
      <path fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="4.5" d="M50 23c-3.83 0-7.47.8-10.77 2.24" />
      <path fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="4.5" d="M39.23 25.24c-3.18 1.38-6.03 3.36-8.44 5.79C25.97 35.91 23 42.61 23 50c0 14.91 12.09 27 27 27s27-12.09 27-27c0-7.4-2.97-14.1-7.79-18.97-1.26-1.28-2.65-2.43-4.15-3.44-1.34-.91-2.78-1.69-4.28-2.35" />
      <path fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="4.5" d="M33 50c0 9.39 7.61 17 17 17s17-7.61 17-17-7.61-17-17-17M50 50.24v-36" />
    </svg>
  )
}