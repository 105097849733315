function LibraryIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
      <g id="bottom-book">
        <path
          id="pages-right"
          fill="#f7f7f7"
          d="M117.47 82.49v.01l.31 12.04-60.06 24.24.12-14.03 58.79-21.89.84-.37z"
        ></path>
        <path
          id="right-page-cover-shadow"
          fill="#ccc"
          d="M117.59 89.75l.18-5.32-54.05 19.46.25 6.38c.02.4.42.66.79.51l52.83-21.02z"
        ></path>
        <path
          id="pages-left"
          fill="#ddd"
          d="M57.71 119.08l.2-8.66-.08-4.36-4.02-4.8L19.14 87.8c-1.26-.99-5.1-3.21-6.69-2.71-.13.05-.26.11-.38.17-.06.04-.13.08-.18.12-.12.09-.23.18-.33.28l-.16.16c-.39.45-1.22 2.17-1.45 2.73-.06.16-.13.33-.19.5v.04c-.1.28-.18.57-.24.87-.03.1-.05.2-.07.3-.28 1.26-.8 2.57-.73 3.86.07 1.84 1.05 3.5 2.48 4.33l46.51 20.64z"
        ></path>
        <path
          id="left-cover-shadow"
          fill="#ccc"
          d="M17.08 90.11c-1.38-2.17-2.29-1.33-6.44-2.27.04-.07 2.18-.82 2.24-.89.05-.07.11-.14.17-.2.12-.11.23-.2.35-.27.06-.04.13-.06.19-.1.12-.07.26-.13.39-.15.26-.08 1.13-1.25 1.42-1.21 1.03.55 1.91 1.54 2.8 2.44l41.86 15.62 6.81 1.91-8.99 3.45"
        ></path>
        <path
          id="page-spine-shadow"
          fill="#b7b7b7"
          stroke="#b7b7b7"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M20.4 88.04c-1.6.23-1.38-.43-4.37-1.21-3.08-.8-5.28 3.63-5.29 6.68 0 .58.02 1.47.11 2.11.39 2.81 3.52 4.41 3.52 4.41-5.64 1.78-12.58-9.43-4.29-13.79 1.59-.84 8.95.83 10.33 1.81z"
        ></path>
        <path
          id="edge-spine-shadow"
          fill="#97464b"
          d="M10.64 101.69C3.96 96.26 6.18 90.45 8.67 86.5l.12-.15c.07-.09.23-.25.32-.34.08-.06.17-.14.27-.22.12-.11.16-.12.26-.19h.01c.07-.06.15-.1.22-.14.07-.03.41-.29.5-.32l-.38.31-.05.07c-.15.15-.25.26-.33.36-2.16 3.1-.09 11.96 3.26 13.82 1.95 1.08 1.54-.66-2.24 1.99z"
        ></path>
        <path
          id="edge-light-fill"
          fill="#bd585e"
          d="M10.02 101.12l4.4-1.28 42.64 19.64c.2.1.44.11.65.02l60.3-24.49.14-.37 2.07 1.2-63.38 26.05-46.83-20.77z"
        ></path>
        <path
          id="lower-outline"
          fill="none"
          stroke="#000"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M121.69 95.92l-64.5 26.38-44.56-19.54C2.81 98.07 5.7 89.9 8.01 86.13l.12-.15c.08-.1.27-.3.39-.41.1-.09.23-.19.37-.3.07-.06.15-.12.25-.18 0 0 .02-.01.03-.02.1-.06.2-.12.3-.17.11-.06.24-.13.37-.17.25-.12.53-.18.8-.23.05-.01.1-.02.14-.02.13-.02.28-.03.42-.03.9-.01 2.48.37 3.7 1.27h-.1c-.79-.38-1.64-.54-2.18-.53-.11 0-.23 0-.34.02-.04 0-.07.01-.11.02-.21 0-.45.09-.63.18-.1.03-.21.08-.29.13-.08.04-.16.08-.23.13 0 .01-.03.02-.04.02-.07.05-.14.09-.2.14-.11.08-.2.17-.28.24l-.11.11c-.08.07-.16.15-.2.22-.07.07-.09.11-.09.11-1.5 2.47-2.56 8.18.03 10.6 1.26 1.04 2.73 2.17 4.16 2.99l43.42 18.99 60.07-24.24v-1.19l3.92 2.28z"
        ></path>
        <path
          id="pages"
          fill="none"
          stroke="#000"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M117.46 83.61l.31 10.04h-.01l.01 1.19-60.07 24.24-43.62-19.09c-1.37-.78-3.13-1.91-4.35-2.89-2.59-2.41-1.13-8.13.37-10.6 0 0 .02-.04.09-.11.04-.07.12-.15.2-.22l.11-.11c.08-.07.17-.16.28-.24.06-.05.13-.09.2-.14.01 0 .03-.01.04-.02.07-.05.15-.09.23-.13.08-.05.19-.1.29-.13.18-.09.42-.17.63-.18.04-.01.07-.02.11-.02.11-.02.23-.02.34-.02.54 0 1.38.15 2.18.54 1.3.67 2.58 1.41 3.68 2.5l39.36 17.86 2.06-.78 57.56-21.67z"
        ></path>
        <path
          id="top-spine"
          fill="#d06167"
          stroke="#000"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M75.24 64.39L14.88 85.7c-1.22-.9-2.8-1.28-3.7-1.27-.14 0-.29.01-.42.03-.04 0-.09.01-.14.02-.27.05-.55.11-.8.23-.13.04-.26.1-.37.17-.1.05-.2.11-.3.17 0 .01-.02.02-.03.02-.1.06-.18.12-.25.18-.14.11-.27.21-.37.3 1.29-1.37 3.77-1.86 6.29-2.85 18.48-7.22 56.04-19.88 56.49-20 .76-.19 2.38.65 3.96 1.67z"
        ></path>
        <path
          id="top-rect"
          fill="#d06167"
          stroke="#000"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M121.24 82.16l-3.78 1.44-57.56 21.66-2.06.79-34.29-15.36c-2.52-1.13-4.98-2.43-7.21-4.06-.47-.34-.95-.66-1.44-.94l60.36-21.31 45.99 17.78z"
        ></path>
      </g>
      <g id="middle-book">
        <path
          id="pages-right-2"
          fill="#e2e2e2"
          d="M9.92 64.67v.01L8.24 77.36l59.2 25.79 1.45-14.77-58.14-23.32-.83-.39z"
        ></path>
        <path
          id="right-page-cover-shadow-2"
          fill="#d1d1d1"
          d="M9.14 68.32l.25-1.61 56.67 22.82-.39 1.58L9.14 68.32z"
        ></path>
        <path
          id="pages-left-2"
          fill="#f7f7f7"
          d="M67.41 103.46l.78-9.12.58-4.59 4.68-5.04 37.26-14.01c1.41-1.04 5.62-3.35 7.21-2.83.13.05.26.11.37.18l.18.12c.11.09.22.19.31.29l.14.17c.35.47 1.02 2.29 1.18 2.88.05.17.09.35.14.53v.04c.07.3.12.6.15.92.02.11.03.21.04.32.15 1.33.54 2.71.31 4.06-.28 1.93-1.48 3.68-3.05 4.55L67.4 103.45z"
        ></path>
        <path
          id="left-cover-shadow-2"
          fill="#d1d1d1"
          d="M112.57 73.14c1.67-2.28 2.51-1.39 6.9-2.37-.04-.07-2.16-.87-2.2-.94-.05-.07-.09-.14-.15-.21-.11-.11-.21-.21-.33-.29-.06-.04-.13-.07-.19-.11a.968.968 0 00-.38-.16c-.26-.08-1.03-1.32-1.33-1.28-1.13.58-2.15 1.61-3.16 2.56L66.8 86.6l-1.13 4.51 2.77 1.15"
        ></path>
        <path
          id="page-spine-shadow-2"
          fill="#c6c6c6"
          stroke="#c6c6c6"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M109.38 70.95c1.63.24 1.47-.44 4.65-1.25 3.27-.83 5.03 3.84 4.71 7.05-.06.61-.19 1.55-.35 2.22-.72 2.96-4.12 4.63-4.12 4.63 5.61 1.9 14.03-9.87 5.98-14.5-1.55-.89-9.32.83-10.85 1.86z"
        ></path>
        <path
          id="edge-spine-shadow-2"
          fill="#bd585e"
          d="M117.9 85.36c7.5-5.69 5.86-11.81 3.74-15.99l-.11-.16c-.06-.09-.21-.26-.29-.36-.07-.07-.16-.15-.26-.23-.11-.11-.15-.13-.25-.2h-.01a1.07 1.07 0 00-.22-.15c-.07-.04-.39-.3-.48-.34l.35.33.05.07c.14.16.23.27.3.38 1.87 3.27-1.26 12.59-4.92 14.54-2.13 1.13-1.51-.7 2.09 2.1z"
        ></path>
        <path
          id="edge-light-fill-2"
          fill="#d06167"
          d="M118.61 84.77l-4.39-1.37-46.19 20.49c-.22.11-.47.11-.67.02L7.95 77.86l-.11-.39-2.27 1.26 62.41 27.71 50.63-21.66z"
        ></path>
        <path
          id="lower-outline-2"
          fill="none"
          stroke="#000"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M4.05 78.8l63.53 28.06 48.16-20.37c10.65-4.89 8.6-13.51 6.65-17.5 0 0-.03-.05-.1-.16s-.24-.31-.35-.44c-.09-.1-.21-.2-.34-.32-.06-.06-.14-.13-.24-.19-.01 0-.02-.01-.03-.02-.1-.06-.19-.13-.29-.18-.11-.06-.23-.14-.36-.18-.24-.12-.53-.19-.8-.24a.784.784 0 00-.14-.02c-.13-.02-.28-.04-.43-.04-.93-.01-2.6.38-3.96 1.32h.1c.86-.4 1.75-.56 2.3-.55.11 0 .24 0 .35.03.04 0 .07.01.11.02.21 0 .45.1.63.19.1.03.21.09.28.14.08.04.15.09.22.14 0 .01.03.02.04.02.07.05.13.1.19.15.1.09.18.18.26.26.04.04.07.09.1.12.07.08.15.16.18.23.06.07.08.12.08.12 1.27 2.61 1.71 8.63-1.23 11.16-1.41 1.09-3.06 2.27-4.62 3.13l-46.91 19.81L8.23 77.7l.14-1.25-4.3 2.38z"
        ></path>
        <path
          id="pages-2"
          fill="none"
          stroke="#000"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M9.81 65.85L8.35 76.42h.01l-.15 1.25 59.2 25.79 47.13-19.9c1.5-.81 3.45-2 4.81-3.02 2.94-2.53 2.09-8.56.82-11.17 0 0-.02-.04-.08-.12a.725.725 0 00-.18-.23c-.03-.03-.06-.07-.1-.12-.07-.08-.16-.17-.26-.26-.06-.05-.12-.1-.19-.15-.01 0-.03-.01-.04-.02-.07-.05-.14-.1-.22-.14-.08-.05-.18-.11-.28-.14-.18-.09-.41-.18-.63-.19-.04-.01-.07-.02-.11-.02-.11-.02-.23-.02-.35-.03-.56 0-1.44.16-2.3.55-1.42.7-2.82 1.48-4.08 2.62l-42.6 18.63-2.04-.83L9.8 65.85z"
        ></path>
        <path
          id="top-spine-2"
          fill="#e36a71"
          stroke="#000"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M55.5 45.8l59.84 22.71c1.36-.94 3.04-1.33 3.96-1.32.14 0 .3.02.43.04.04 0 .09.01.14.02.27.05.55.12.8.24.13.04.26.11.36.18.1.05.19.12.29.18 0 .01.02.02.03.02.1.06.17.13.24.19.13.12.25.23.34.32-1.18-1.45-3.68-1.98-6.16-3.03-18.24-7.68-55.54-21.18-55.99-21.31-.76-.2-2.53.68-4.27 1.74z"
        ></path>
        <path
          id="top-rect-2"
          fill="#e36a71"
          stroke="#000"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M6.07 64.31l3.74 1.54 56.91 23.06 2.04.84 37.09-16.02c2.73-1.18 5.41-2.53 7.89-4.25.52-.36 1.05-.69 1.59-.98L55.49 45.79 6.07 64.31z"
        ></path>
      </g>
      <g id="top-book">
        <path
          id="pages-right-3"
          fill="#fff"
          d="M118.87 43.6v.01l.32 12.04-60.07 24.24.12-14.03 58.79-21.89.84-.37z"
        ></path>
        <path
          id="right-page-cover-shadow-3"
          fill="#eaeaea"
          d="M119.25 47.06l-.08-1.52-57.3 21.43.21 1.5 57.17-21.41z"
        ></path>
        <path
          id="pages-left-3"
          fill="#eaeaea"
          d="M59.11 80.19l.2-8.66-.08-4.36-4.02-4.8-34.67-13.46c-1.26-.99-5.1-3.21-6.69-2.71-.13.05-.26.11-.38.17-.06.04-.13.08-.18.12-.12.09-.23.18-.33.28l-.16.16c-.39.45-1.22 2.17-1.45 2.73-.06.16-.13.33-.19.5v.04c-.1.28-.18.57-.24.87-.03.1-.05.2-.07.3-.28 1.26-.8 2.57-.73 3.86.07 1.84 1.05 3.5 2.48 4.33L59.11 80.2z"
        ></path>
        <path
          id="left-cover-shadow-3"
          fill="#dbdbdb"
          d="M18.48 51.22c-1.38-2.17-2.29-1.33-6.44-2.27.04-.07 2.18-.82 2.24-.89.05-.07.11-.14.17-.2.12-.11.23-.2.35-.27.06-.04.13-.06.19-.1.12-.07.26-.13.39-.15.26-.08 1.13-1.25 1.42-1.21 1.03.55 1.91 1.54 2.8 2.44l41.86 15.62.62 4.28-2.8 1.08"
        ></path>
        <path
          id="page-spine-shadow-3"
          fill="#b2b2b2"
          stroke="#b2b2b2"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M21.8 49.15c-1.6.23-1.38-.43-4.37-1.21-3.08-.8-5.28 3.63-5.29 6.68 0 .58.02 1.47.11 2.11.39 2.81 3.52 4.41 3.52 4.41-5.64 1.78-12.58-9.43-4.29-13.79 1.59-.84 8.95.83 10.33 1.81z"
        ></path>
        <path
          id="edge-spine-shadow-3"
          fill="#bd585e"
          d="M12.05 62.8c-6.68-5.43-4.46-11.24-1.97-15.19l.12-.15c.07-.09.23-.25.32-.34.08-.06.17-.14.27-.22.12-.11.16-.12.26-.19h.01c.07-.06.15-.1.22-.14.07-.03.41-.29.5-.32l-.38.31-.05.07c-.15.15-.25.26-.33.36-2.16 3.1-.09 11.96 3.26 13.82 1.95 1.08 1.54-.66-2.24 1.99z"
        ></path>
        <path
          id="edge-light-fill-3"
          fill="#d06167"
          d="M11.42 62.23l4.4-1.28 42.64 19.64c.2.1.44.11.65.02l60.3-24.49.14-.37 2.07 1.2L58.24 83 11.42 62.23z"
        ></path>
        <path
          id="lower-outline-3"
          fill="none"
          stroke="#000"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M123.1 57.03L58.6 83.41 14.03 63.88C4.21 59.19 7.1 51.02 9.41 47.25l.12-.15c.08-.1.27-.3.39-.41.1-.09.23-.19.37-.3.07-.06.15-.12.25-.18 0 0 .02-.01.03-.02.1-.06.2-.12.3-.17.11-.06.24-.13.37-.17.25-.12.53-.18.8-.23.05-.01.1-.02.14-.02.13-.02.28-.03.42-.03.9-.01 2.48.37 3.7 1.27h-.1c-.79-.38-1.64-.54-2.18-.53-.11 0-.23 0-.34.02-.04 0-.07.01-.11.02-.21 0-.45.09-.63.18-.1.03-.21.08-.29.13-.08.04-.16.08-.23.13 0 .01-.03.02-.04.02-.07.05-.14.09-.2.14-.11.08-.2.17-.28.24l-.11.11c-.08.07-.16.15-.2.22-.07.07-.09.11-.09.11-1.5 2.47-2.56 8.18.03 10.6 1.26 1.04 2.73 2.17 4.16 2.99l43.42 18.99 60.07-24.24v-1.19l3.92 2.28z"
        ></path>
        <path
          id="pages-3"
          fill="none"
          stroke="#000"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M118.86 44.72l.31 10.04h-.01l.01 1.19L59.1 80.19 15.49 61.1c-1.37-.78-3.13-1.91-4.35-2.89-2.59-2.41-1.13-8.13.37-10.6 0 0 .02-.04.09-.11.04-.07.12-.15.2-.22l.11-.11c.08-.07.17-.16.28-.24.06-.05.13-.09.2-.14.01 0 .03-.01.04-.02.07-.05.15-.09.23-.13.08-.05.19-.1.29-.13.18-.09.42-.17.63-.18.04-.01.07-.02.11-.02.11-.02.23-.02.34-.02.54 0 1.38.15 2.18.54 1.3.67 2.58 1.41 3.68 2.5l39.36 17.86 2.06-.78 57.56-21.67z"
        ></path>
        <path
          id="top-spine-3"
          fill="#e77980"
          stroke="#000"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M76.65 25.5L16.29 46.81c-1.22-.9-2.8-1.28-3.7-1.27-.14 0-.29.01-.42.03-.04 0-.09.01-.14.02-.27.05-.55.11-.8.23-.13.04-.26.1-.37.17-.1.05-.2.11-.3.17 0 .01-.02.02-.03.02-.1.06-.18.12-.25.18-.14.11-.27.21-.37.3 1.29-1.37 3.77-1.86 6.29-2.85 18.48-7.22 56.04-19.88 56.49-20 .76-.19 2.38.65 3.96 1.67z"
        ></path>
        <path
          id="top-rect-3"
          fill="#e77980"
          stroke="#000"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M122.64 43.27l-3.78 1.44L61.3 66.37l-2.06.79L24.95 51.8c-2.52-1.13-4.98-2.43-7.21-4.06-.47-.34-.95-.66-1.44-.94l60.36-21.31 45.99 17.78z"
        ></path>
        <path
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="M72.24 33.45l22.3 8.81m-25.65-7.47l22.3 8.81M65.5 35.91l22.3 8.81m-22.36-5.91l15.59 6.16"
        ></path>
      </g>
    </svg>
  );
}

export default LibraryIcon;