import { InitialWindowConfigMap } from "../types/types";
import ProfileWindow from '../components/Windows/content/ProfileWindow/ProfileWindow';
import PortfolioWindow from '../components/Windows/content/PortfolioWindow/PortfolioWindow';
import LibraryWindow from '../components/Windows/content/LibraryWindow/LibraryWindow';
import ContactWindow from '../components/Windows/content/ContactWindow/ContactWindow';
import { AppsWindow } from "../components/Windows/content/AppsWindow/AppsWindow";
import { ProfileDockIcon } from "../components/ui/dockIcons/tempProfileDockIcon/ProfileDockIcon";
import { PortfolioDockIcon } from "../components/ui/dockIcons/PortfolioDockIcon/PortfolioDockIcon";
import { LibraryDockIcon } from "../components/ui/dockIcons/LibraryDockIcon/LibraryDockIcon";
import { ContactDockIcon } from "../components/ui/dockIcons/ContactDockIcon/ContactDockIcon";
import { AppsDockIcon } from "../components/ui/dockIcons/AppsDockIcon/AppsDockIcon";

export const initialWindowConfigMap: InitialWindowConfigMap = {

  'profile': {
    title: 'PROFILE',
    initialLayoutParams: {
      left: '20%',
      top: '8%',
      width: '45%',
      height: '70%'
    },
    minimumSize: {
      width: 100,
      height: 100
    },
    content: <ProfileWindow />,
    dockIcon: <ProfileDockIcon />,
    infoText: "This window lists key skills and qualifications, providing a snapshot of core competencies and areas of interest."
  },

  'portfolio': {
    title: 'PORTFOLIO',
    initialLayoutParams: {
      top: '10%',
      left: '10%',
      width: '58%',
      height: '65%'
    },
    minimumSize: {
      width: 100,
      height: 100
    },
    content: <PortfolioWindow />,
    dockIcon: <PortfolioDockIcon />,
    infoText: "This window contains a portfolio of past projects with links to production sites and detailed project information."
  },

  'library': {
    title: 'LIBRARY',
    initialLayoutParams: {
      top: '10%',
      left: '15%',
      width: '60%',
      height: '70%'
    },
    minimumSize: {
      width: 100,
      height: 100
    },
    content: <LibraryWindow />,
    dockIcon: <LibraryDockIcon />,
    infoText: "The Library displays a collection of books in the field of Computer Science, Technology and Design, with an emphasis on Software Engineering and Web Development. Due to copyright requirements, full or partial access is available only for selected book titles. Please log in for additional access (if applicable)."
  },

  'contact': {
    title: 'CONTACT',
    initialLayoutParams: {
      top: '20%',
      left: '30%',
      width: '30%',
      height: '50%'
    },
    minimumSize: {
      width: 342,
      height: 185
    },
    content: <ContactWindow />,
    dockIcon: <ContactDockIcon />,
    infoText: "This window provides a form for easy submission of feedback, queries or enquires. Alternatively, get in touch using the following email address: peter@petergregory.dev"
  },

  'apps': {
    title: 'APPS',
    initialLayoutParams: {
      left: '26%',
      top: '34%',
      width: '25%',
      height: '40%'
    },
    minimumSize: {
      width: 342,
      height: 185
    },
    content: <AppsWindow />,
    dockIcon: <AppsDockIcon />,
    infoText: "This window displays the main menu, where you'll find all the essential features on this website. Click on one of the icons to get started."
  }
}

export type windowId = keyof typeof initialWindowConfigMap





