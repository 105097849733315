import Dock from '../Dock/Dock'
import TopMenu from '../TopMenu/TopMenu'
import Windows from '../Windows/Windows'
import { Background } from './Background/Background'
import styles from './App.module.sass'
import { useState } from 'react'
import { initialWindowStates } from '../../config/initialWindowStates'
import { Notification, WindowStates } from '../../types/types'
import AppContext from './AppContext'
import { DockVoid } from '../Dock/DockVoid/DockVoid'
import useViewportSize from '../../hooks/useViewportSize'

type AppProps = {
  firstVisit: boolean,
  setFirstVisit: React.Dispatch<React.SetStateAction<boolean>>
}

export const App: React.FC<AppProps> = ({
  firstVisit,
  setFirstVisit
}) => {


  // const initNotification: Notification = {
  //   subject: 'Welcome',
  //   isNew: true,
  //   source: '',
  //   action: '',
  //   timeCreated: Date.now()
  // }

  // const secondNotif: Notification = {
  //   subject: 'Message Sent',
  //   isNew: false,
  //   source: '',
  //   action: '',
  //   timeCreated: Date.now()
  // }

  // const notif3: Notification = {
  //   subject: "Welcome. Thank you for visiting.",
  //   isNew: false,
  //   source: "Home",
  //   action: "Visited Website.",
  //   timeCreated: 0,
  //   details: [["Happiness level", "100"], ["Message Content", "Oh yes hi just wanted to say blah blah thanks so much for bluh bluh bluh"]]
  // }

  const [windows, setWindows] = useState<WindowStates>(initialWindowStates)
  const [dockHidden, setDockHidden] = useState<boolean>(false)
  const [notifications, setNotifications] = useState<Notification[]>([])
  const { isMobile } = useViewportSize()

  return (
    <AppContext.Provider value={{
      windows,
      setWindows,
      dockHidden,
      setDockHidden,
      firstVisit,
      setFirstVisit,
      notifications,
      setNotifications
    }}>
      <div className={styles.app}>
        <TopMenu />
        {isMobile ? null : <Background />}
        <Windows />
        {isMobile ? null : dockHidden ? <DockVoid /> : <Dock />}
      </div>
    </AppContext.Provider>
  )
}