import { ResourcesConfig } from 'aws-amplify';

const amplifyConfig: ResourcesConfig = {
  Auth: {
    Cognito: {
      userPoolId: 'ap-southeast-2_npqCVfmAP',
      userPoolClientId: '5213v8634vcm3mpofv2hiss4tq',
      loginWith: {
        username: true
      }
    }
  }
}

export default amplifyConfig