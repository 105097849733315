
type EnterFullScreenIconProps = {
}

export const EnterFullScreenIcon: React.FC<EnterFullScreenIconProps> = () => {
  return (
    <svg viewBox="0 0 100 100">
      <rect x="5" y="5" width="90" height="90" vectorEffect='non-scaling-stroke' fill="none" stroke="#000"/>
    </svg>
    // <svg viewBox="0 0 100 100" className={styles.fullScreen}>
    //   <rect x="35" y="15" width="50" height="50" vectorEffect='non-scaling-stroke' fill="none" stroke="#000"/>
    //   <rect x="15" y="35" width="35" height="35" fill="white" vectorEffect='non-scaling-stroke' stroke="#000"/>
    // </svg>
  )
}