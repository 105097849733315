import styles from './VerticalLineDivider.module.sass'

type VerticalLineDividerProps = {
}

export const VerticalLineDivider: React.FC<VerticalLineDividerProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="version2" viewBox="0 0 10 100" >
      <line x1="5" x2="5" y1="0" y2="100" stroke="rgb(115,115,115)" strokeWidth="1.4px" vectorEffect="non-scaling-stroke"/>
    </svg>
  )
}