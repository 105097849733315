import { TabSvg } from '../../../../ui/TabSvg/TabSvg';
import { VerticalLineDivider } from '../../../../ui/VerticalLineDivider/VerticalLineDivider';
import styles from './TabMenuItem.module.sass'

type TabMenuItemProps = {
  title: string
  isCurrentTab: boolean;
  index: number
  isLeftOfCurrentTab: boolean
  isLastTab: boolean
}

const TabMenuItem: React.FC<TabMenuItemProps> = ({
  title,
  isCurrentTab,
  index,
  isLeftOfCurrentTab,
  isLastTab
}) => {
  return (
    <div className={styles.tabMenuItem}>

      {isCurrentTab ?
        <div className={styles.currentTabSvgContainer}>
          <TabSvg />
        </div> : null
      }

      {/* <div className={styles.tabContents}> */}

      <h2>{title}</h2>

      {isCurrentTab || isLeftOfCurrentTab || isLastTab ? null :
        <div className={styles.divider}>
          <VerticalLineDivider />
        </div>
      }
    </div>

    // </div>
  )
}

export default TabMenuItem