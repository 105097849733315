import { SetStateAction, useEffect, useRef, useState } from 'react'
import { BookCollection } from '../../../../../../types/library/bookTypes'
import { BookView, LibraryState, LibraryView, imageKeyUrlMap } from '../../../../../../types/library/libraryUI'
import SignedUrlImage from '../../../../../ui/SignedUrlImage/SignedUrlImage'
import styles from './RightMainPanel.module.sass'
import { BouncingBall } from '../../../../../ui/BouncingBall/BouncingBall'

type RightMainPanelProps = {
  bookResults: BookCollection,
  bookCollectionCount: number,
  setLibraryView: React.Dispatch<React.SetStateAction<LibraryState>>
  imageUrlCache: imageKeyUrlMap
  setImageUrlCache: React.Dispatch<React.SetStateAction<imageKeyUrlMap>>
  failedImageKeys: Set<string>
  setFailedImageKeys: React.Dispatch<SetStateAction<Set<string>>>
}

export const RightMainPanel: React.FC<RightMainPanelProps> = ({
  bookResults,
  bookCollectionCount,
  setLibraryView,
  imageUrlCache,
  setImageUrlCache,
  failedImageKeys,
  setFailedImageKeys
}) => {

  const galleryRef = useRef<HTMLDivElement>(null)
  const [booksDisplayed, setBooksDisplayed] = useState<BookCollection>({})
  const batchSize = 20

  const [throttlePause, setThrottlePause] = useState<boolean>(false);
  const throttle = (callback: (e: React.UIEvent<HTMLDivElement>) => void, event: React.UIEvent<HTMLDivElement, UIEvent>, time: number) => {
    if (throttlePause) return;
    setThrottlePause(true)
    setTimeout(() => {
      callback(event)
      setThrottlePause(false)
    }, time);
  };

  const getMoreResults = (refresh: boolean) => {
    // console.log("gettingMoreResults")
    let startIndex = refresh ? 0 : Object.keys(booksDisplayed).length
    // console.log("startIndex:", startIndex)
    const results = Object.entries(bookResults)
    // console.log("results.length:", results.length)
    const displayBatch: BookCollection = {}
    for (let i = startIndex; i < startIndex + batchSize && i < results.length; i++) {
      let [bookName, bookData] = results[i]
      displayBatch[bookName] = bookData
    }
    // console.log("displayBatch:", displayBatch)
    setBooksDisplayed(prev => ({ ...prev, ...displayBatch }))
  }

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    // onsole.log("triggered handleScroll")
    const container = e.target as HTMLDivElement

    if (!galleryRef.current) return;
    // console.log("galleryRef.current")


    const galleryHeight = galleryRef.current?.getBoundingClientRect().height
    const displayedHeight = container.getBoundingClientRect().height
    const scrolledHeight = container.scrollTop
    const endPosition = displayedHeight + scrolledHeight

    // console.log("endPosition:", endPosition)
    // console.log("galleryHeight:", galleryHeight)


    const threshold = 1 // A small threshold to account for sub-pixel differences

    if (endPosition + threshold >= galleryHeight)
      getMoreResults(false)
  }

  const bookView: BookView = {
    viewType: LibraryView.Book,
    bookId: ''
  }

  const setViewToBook = (bookRef: string) => {
    if (!bookRef) throw new Error("Cannot setViewToBook: bookRef is undefined")
    if (bookRef === "") throw new Error("Empty string found for bookRef")
    bookView.bookId = bookRef
    setLibraryView(bookView)
  }

  const handleImageError = (bookRef: string) => {
    setFailedImageKeys(prev => new Set([...prev, bookRef]))
  }

  useEffect(() => {
    if (bookResults) {
      setBooksDisplayed({})
      getMoreResults(true)
    }
  }, [bookResults])

  return (
    <div className={styles.rightMainPanel}>

      <div className={styles.headingContainer}>
        <h3>RESULTS: [
          {Object.keys(bookResults).length == bookCollectionCount ?
            `showing ${bookCollectionCount} books` :
            `showing ${Object.keys(bookResults).length} of ${bookCollectionCount} books`
          }
          ]
        </h3>
        {/* <h3> RESULTS: [showing {Object.keys(bookResults).length}] of {bookCollectionCount}</h3> */}
      </div>

      <div className={styles.galleryContainer}
        onScroll={(Object.keys(bookResults).length > Object.keys(booksDisplayed).length
        ) ? (e) => throttle(handleScroll, e, 250) : undefined}>
        <div className={styles.gallery} ref={galleryRef}>
          {Object.entries(booksDisplayed)
            .map(([bookRef, book]) => {
              return (
                failedImageKeys.has(book.coverImageKey) ? null :
                  <div key={bookRef} className={styles.bookCover}>
                    <div className={styles.imageWrapper} onClick={() => setViewToBook(bookRef)}>
                      <SignedUrlImage
                        imageKey={book.coverImageKey}
                        imageUrlCache={imageUrlCache}
                        setImageUrlCache={setImageUrlCache}
                        onError={() => handleImageError(book.coverImageKey)}
                      />
                    </div>
                  </div>
              )
            })}
        </div>

        <div className={styles.galleryEnd}>
          <div className={styles.bouncingBall}>
            {(Object.keys(bookResults).length > Object.keys(booksDisplayed).length) && <BouncingBall />}
          </div>
        </div>

      </div>


    </div>
  )
} 