import { InitialisedWindowValues, WindowStates } from "../types/types";
import useWindowManager from "./useWindowManager";
import { WindowTemplateProps } from "../components/Windows/windowTemplates/WindowTemplate/WindowTemplate";
import { useEffect } from "react";

const useWindowSetup = (
  boundingElementRef: React.RefObject<HTMLDivElement>,
  windows: WindowStates,
  setWindows: React.Dispatch<React.SetStateAction<WindowStates>>
) => {

  const {
    getZIndex,
    openWindow,
    closeWindow,
    isWindowOpen,
    isWindowMinimised,
    isWindowFullScreen,
    isWindowInFront,
    bringWindowToFront,
    setFrontWindow
  } = useWindowManager(windows, setWindows)

  useEffect(() => {
    setFrontWindow()
  }, [])

  const createWindowProps = (windowId: string, initialWindowValues: InitialisedWindowValues): WindowTemplateProps =>
  ({
    windowId: windowId,
    isThisWindowOpen: isWindowOpen(windowId),
    isThisWindowMinimised: isWindowMinimised(windowId),
    isThisWindowFullScreen: isWindowFullScreen(windowId),
    isWindowInFront: isWindowInFront(windowId),
    openThisWindow: () => openWindow(windowId),
    closeThisWindow: () => closeWindow(windowId),
    title: initialWindowValues.title,
    zIndex: getZIndex(windowId),
    bringThisWindowToFront: () => bringWindowToFront(windowId),
    initialLayoutParams: initialWindowValues.layoutParams,
    minimumSize: {
      width: initialWindowValues.minimumSize.width,
      height: initialWindowValues.minimumSize.height
    },
    boundingElementRef: boundingElementRef,
    infoText: initialWindowValues.infoText
  })

  return ({ createWindowProps })
}

export default useWindowSetup