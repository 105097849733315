import styles from './PortfolioWindow.module.sass'
import UrbanitePortfolioItem from './portfolioItems/projects/UrbanitePortfolioItem'
import { useState } from 'react'
import Slider from './Slider/Slider'
import ArrowFlatLeft from '../../../ui/icons/ArrowFlatLeft/ArrowFlatLeft'
import ArrowFlatRight from '../../../ui/icons/ArrowFlatRight/ArrowFlatRight'


const PortfolioWindow = () => {

  const slides = ['Urbanite', 'Alice.Cleaning', 'VectorArt']

  const [currentSlide, updateSlide] = useState<string>(slides[0])

  const [isSidePanelMinimised, minimiseSidePanel] = useState<boolean>(false)

  return (

    <div className={styles.portfolioWindow}>

      <div className={styles.leftSidePanel}>

        <div className={styles.arrow}>
          {isSidePanelMinimised

            ?
            <div onClick={e => { minimiseSidePanel(false) }}>
              <ArrowFlatRight />
            </div>

            :
            <div onClick={e => { minimiseSidePanel(true) }}>
              <ArrowFlatLeft />
            </div>
          }
        </div>


        <Slider
          slides={slides}
          currentSlide={currentSlide}
          updateSlide={updateSlide} 
          minimised={isSidePanelMinimised}
          />

      </div>

      <div className={styles.rightMainPanel}>

        {(() => {
          switch (currentSlide) {
            case 'Urbanite':
              return <UrbanitePortfolioItem />
            case 'Alice.Cleaning':
              return <UrbanitePortfolioItem />
            case 'VectorArt':
              return <UrbanitePortfolioItem />
          }
        })()}

      </div>
    </div >

  )
}

export default PortfolioWindow