import { SetStateAction, useEffect, useState } from 'react'
import styles from './LibraryWindow.module.sass'
import { BookCollection } from '../../../../types/library/bookTypes';
import { SubjectIndex } from '../../../../types/library/subjectIndexTypes';
import { CollectionView } from './CollectionView/CollectionView';
import { LibraryState, LibraryView, imageKeyUrlMap } from '../../../../types/library/libraryUI';
import { BookView } from './BookView/BookView';


const urlRoot = 'https://petergregory.dev/.netlify/functions/'

const LibraryWindow = () => {

  const [bookCollection, setBookCollection] = useState<BookCollection>({})
  const [subjectIndex, setSubjectIndex] = useState<SubjectIndex>({})
  const [error, setError] = useState(null)
  const [bookTitlesList, setBookTitlesList] = useState<string[]>([])
  const [coverImageUrlList, setCoverImageUrlList] = useState<string[]>([])
  const [libraryView, setLibraryView] = useState<LibraryState>({ viewType: LibraryView.Collection })
  const [imageUrlCache, setImageUrlCache] = useState<imageKeyUrlMap>(new Map())
  const [failedImageKeys, setFailedImageKeys] = useState<Set<string>>(new Set())

  useEffect(() => {

    const url = urlRoot + 'getBookCollection'
    const getBookCollection = async () => {
      try {
        const res = await fetch(url)
        if (!res.ok) throw new Error(`HTTP error! Status: ${res.status}`)
        // const text = await res.text()
        // if (!text) throw new Error("Failed to get text from response: getBookCollection")
        // const data = JSON.parse(text)
        // if (!data) throw new Error("Failed to parse text into JSON: getBookCollection")
        // const dataObj = JSON.parse(data)
        // if (!dataObj) throw new Error("Failed to parse data into dataObj")
        //   setBookCollection(dataObj)
        const data = await res.json()
        if (!data) throw new Error("Failed to parse data into data")
        setBookCollection(data)
      } catch (err: any) {
        console.error(err)
      }
    }
    getBookCollection()
  },
    [])

  useEffect(() => {

    const url = urlRoot + 'getSubjectIndex'
    const getSubjectIndex = async () => {
      try {
        const res = await fetch(url)
        if (!res.ok) throw new Error(`HTTP error! Status: ${res.status}`)
        // const text = await res.text()
        // if (!text) throw new Error("Failed to get text from response: getSubjectIndex")
        // const data = JSON.parse(text)
        // if (!data) throw new Error("Failed to parse text into JSON: getSubjectIndex")
        // const dataObj = JSON.parse(data)
        // if (!dataObj) throw new Error("Failed to parse data into dataObj")
        // setSubjectIndex(dataObj)
        // if (!data) throw new Error("Failed to parse text into JSON: getSubjectIndex")
        const data = await res.json()
        if (!data) throw new Error("Failed to parse data into data")
        setSubjectIndex(data)
      } catch (err: any) {
        console.error(err)
      }
    }
    getSubjectIndex()
  },
    []
  )

  const getBookFromId = (id: string) => {
    const matchedBook = Object.entries(bookCollection).find(([bookName, _]) => bookName === id)
    if (!matchedBook) throw new Error("Could not getBookFromId since no match found.")
    const [, matchedBookObj] = matchedBook
    return matchedBookObj
  }

  return (
    <div className={styles.libraryWindow}>

      {libraryView.viewType == LibraryView.Book ?

        <BookView
          book={getBookFromId(libraryView.bookId)}
          setLibraryView={setLibraryView}
          imageUrlCache={imageUrlCache}
          setImageUrlCache={setImageUrlCache} 
          failedImageKeys={failedImageKeys}
          setFailedImageKeys={setFailedImageKeys}
          /> :

        <CollectionView
          subjectIndex={subjectIndex}
          error={error}
          bookCollection={bookCollection}
          setLibraryView={setLibraryView}
          imageUrlCache={imageUrlCache}
          setImageUrlCache={setImageUrlCache} 
          failedImageKeys={failedImageKeys}
          setFailedImageKeys={setFailedImageKeys}
          />
      }

    </div>
  )
}

export default LibraryWindow