import { ProfileTab } from "../ProfileTab/ProfileTab"

const UxUiTab: React.FC = () => {
  return (
    <ProfileTab>
      <div>
        <p>Design Tools: </p>
        <ul>
          <li>
            Digital assets management
          </li>
          <li>
            Graphic design, experience with Art Directors, Graphic Designers – effectively bridge design and functionality, carefully translate a creative's vision into a functioning product.
          </li>
          <li>
            SVG: creating and refining vector graphics using SVG markup code
          </li>
          <li>
            Adobe Illustrator, Photoshop, XD, Figma.
          </li>
          <li>
            Photoshop, digital image manipulation.
          </li>
        </ul>
      </div>
    </ProfileTab>
  )
}

export default UxUiTab
