import { BookCollection } from '../../../../../types/library/bookTypes'
import { SubjectIndex } from '../../../../../types/library/subjectIndexTypes'
import { LeftSidePanel } from './LeftSidePanel/LeftSidePanel'
import { RightMainPanel } from './RightMainPanel/RightMainPanel'
import styles from './CollectionView.module.sass'
import { LibraryState, imageKeyUrlMap } from '../../../../../types/library/libraryUI'
import { SetStateAction, useEffect, useState } from 'react'

type CollectionViewProps = {
  subjectIndex: SubjectIndex,
  error: any,
  bookCollection: BookCollection
  setLibraryView: React.Dispatch<React.SetStateAction<LibraryState>>
  imageUrlCache: imageKeyUrlMap
  setImageUrlCache: React.Dispatch<React.SetStateAction<imageKeyUrlMap>>
  failedImageKeys: Set<string>
  setFailedImageKeys: React.Dispatch<SetStateAction<Set<string>>>
}

export const CollectionView: React.FC<CollectionViewProps> = ({
  subjectIndex,
  error,
  bookCollection,
  setLibraryView,
  imageUrlCache,
  setImageUrlCache,
  failedImageKeys,
  setFailedImageKeys
}) => {

  const [bookResults, setBookResults] = useState<BookCollection>({})

  return (
    <div className={styles.collectionView}>
      <LeftSidePanel
        subjectIndex={subjectIndex}
        error={error} 
        bookCollection={bookCollection}
        setBookResults={setBookResults}/>
      <RightMainPanel
        bookResults={bookResults}
        bookCollectionCount={Object.keys(bookCollection).length}
        setLibraryView={setLibraryView}
        imageUrlCache={imageUrlCache}
        setImageUrlCache={setImageUrlCache}
        failedImageKeys={failedImageKeys}
        setFailedImageKeys={setFailedImageKeys}
      />
    </div>

  )
}