import { AppBackgroundShape } from '../../../../ui/AppBackgroundShape/AppBackgroundShape'
import AppLabel from '../AppLabel/AppLabel'
import styles from './AppTemplate.module.sass'

type AppTemplateProps = {
  appId: string
  appTitle: string
  appImage: JSX.Element
  openOnTouch: (windowId: string) => void
  isWindowOpen: (windowId: string) => boolean
}

export const AppTemplate: React.FC<AppTemplateProps> = ({
  appId,
  appTitle,
  appImage,
  openOnTouch: openAppIcon,
  isWindowOpen
}) => {

  return (
    <div className={styles.appContainer} onClick={e => openAppIcon(appId)}>
      <div className={styles.appIconContainer}>
        <div className={styles.iconBackground}>
          <AppBackgroundShape />
        </div>
        <div className={styles.iconImage}>
          {appImage}
        </div>
      </div>
      <AppLabel appName={appTitle} isOpen={isWindowOpen(appId)} />
    </div>
  )
}