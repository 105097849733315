import styles from './TabSvg.module.sass'


export const TabSvg:React.FC = () => {
  return(

<svg xmlns="http://www.w3.org/2000/svg" id="version2" viewBox="0 0 100 100" preserveAspectRatio="none"><path d="M0,100s5.88.35,5.88-5.88,0-85.68,0-85.68C5.88,8.43,5.74,0,14.31,0h35.69S85.69,0,85.69,0c8.57,0,8.43,8.43,8.43,8.43,0,0,0,79.45,0,85.68s5.88,5.88,5.88,5.88" fill="rgb(240,240,240)" strokeWidth="0px"/></svg>


    // <svg
    //   className={styles.tab}
    //   xmlns="http://www.w3.org/2000/svg"
    //   viewBox="0 0 100 100"
    //   preserveAspectRatio="none"
    // >
    //   <path
    //     d="M0.5,100 l0,-80 q0,-15 4,-15 l91,0 q4,0 4,15 l0,80"
    //     vectorEffect="non-scaling-stroke"
    //     fill="#fff"
    //     strokeLinecap='square'
    //   />
    //   <line
    //     x1='0.5'
    //     y1='99'
    //     x2='99.5'
    //     y2='99'
    //     vectorEffect="non-scaling-stroke"
    //     stroke="#fff"
    //     strokeWidth='0'
    //     strokeLinecap='butt'
    //   />
    //   <line
    //     x1='0'
    //     x2='0.5'
    //     y1='99'
    //     y2='99'
    //     stroke="#000000"
    //     strokeWidth='1'
    //     vectorEffect="non-scaling-stroke"
    //   />
    // </svg>
  )
}