import styles from './DockOverlayLeft.module.sass'

type DockOverlayLeftProps = {
}

export const DockOverlayLeft: React.FC<DockOverlayLeftProps> = () => {
  return (

    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
      {/* <rect width="100%" height="100%" x="0" y="0" fill="rgb(150,150,150)" opacity="1"/> */}
      <path d="M0,0 v100 l90,-5.5 v-88.5 z" fill="rgb(120,120,120)" fillOpacity="0.6" />

    </svg>
  )
}


//rgb(120,120,120)
