import styles from './TabMenu.module.sass'
import TabMenuItem from './TabMenuItem/TabMenuItem'

type TabMenuProps = {
  titles: string[]
  currentTab: string
  updateCurrentTab: React.Dispatch<React.SetStateAction<string>>
}

const TabMenu: React.FC<TabMenuProps> = ({
  titles,
  currentTab,
  updateCurrentTab
}) => {

  const clickSectionUpdateCurrentTab = (e: React.MouseEvent<HTMLDivElement>, title: string) => {
    updateCurrentTab(title)
  }

  return (
    <div className={styles.tabMenu}>
      <div className={styles.tabs}>

        {titles.map(title => {

          const currentTabClass = `${styles.section} ${styles.currentSection}`

          return (
            <div
              className={currentTab == title ? currentTabClass : styles.section}
              onClick={(e) => clickSectionUpdateCurrentTab(e, title)}>
              <TabMenuItem
                isCurrentTab={(currentTab == title)}
                title={title}
                index={titles.indexOf(title)}
                isLeftOfCurrentTab={titles.indexOf(currentTab) - titles.indexOf(title) == 1}
                isLastTab={titles.indexOf(title) == titles.length - 1} />

            </div>
          )
        })}
      </div>
    </div >
  )
}


export default TabMenu