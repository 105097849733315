import { Tab } from '../../../../../layouts/TabLayout/Tab/Tab';
import TabLayout from '../../../../../layouts/TabLayout/TabLayout';
import styles from './ProjectTemplate.module.sass'

type asset = {
  fileName: string
  fileImage: () => JSX.Element
  fileContentSrc: string
}

type ProjectTemplateProps = {
  name: string;
  url: string;
  overviewDescription: string;
  mainImageSrc: string;
  techStackDescription: string,
  assets?: asset[]
}


const ProjectTemplate: React.FC<ProjectTemplateProps> = ({
  name,
  url,
  overviewDescription,
  mainImageSrc,
  techStackDescription,
  assets
}) => {

  const openPdfViewer = (fileContentSrc: string) => { }


  return (
    <div className={styles.item}>

      <TabLayout>
        <Tab title='Overview'>
          <div>
            <p>
              {name}
            </p>
            <p>
              {overviewDescription}
            </p>
            <img src={mainImageSrc} className={styles.sampleImage} />
          </div>
        </Tab>
        <Tab title='Tech Stack'>
          <div>
            <p>{techStackDescription}</p>
          </div>
        </Tab>
        <Tab title='Assets'>
          <div>
            {assets?.map(asset => {
              <div className={styles.assetItem} onClick={e => openPdfViewer(asset.fileContentSrc)}>
                <asset.fileImage />
                <h4>{asset.fileName}</h4>
              </div>
            })}
          </div>
        </Tab>
        <Tab title='Link to Website'>
          <div>
            <p>{url}</p>
          </div>
        </Tab>
      </TabLayout>

    </div>
  )
}

export default ProjectTemplate