import CircleEmpty from '../../../../ui/icons/CircleEmpty/CircleEmpty';
import Circle from '../../../../ui/icons/Circle/Circle';
import styles from './Slider.module.sass'

type SliderProps = {
  slides: string[];
  currentSlide: string;
  updateSlide: React.Dispatch<React.SetStateAction<string>>;
  minimised: boolean
}

const Slider: React.FC<SliderProps> = ({
  slides,
  currentSlide,
  updateSlide,
  minimised
}) => {
  return (
    <div className={minimised ? styles.minimsed : styles.maximised}>
      {slides.map(slide => (

        <div className={slide == currentSlide ? `${styles.slider} + ' ' + ${styles.current}` : styles.slider}
          onClick={(e) => updateSlide(slide)}
        >
          <div className={styles.circleContainer}>
            {slide == currentSlide ? <Circle /> : <CircleEmpty />}
          </div>
          {(!minimised) ? <h3>{slide}</h3> : null}

        </div>
      )

      )}
    </div>
  )
}

export default Slider