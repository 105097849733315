import styles from './DockOverlayTop.module.sass'

type DockOverlayTopProps = {
}

export const DockOverlayTop:React.FC<DockOverlayTopProps> = () => {
  return(

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
          {/* <filter id="highlight10">
            <feSpecularLighting
              specularExponent="4"
              lighting-color="#ffffff">
            </feSpecularLighting>
          </filter>
          <filter id="shadow">
            <feFlood
              flood-color="#9b9b9b"
              flood-opacity="0.9"
            />
          </filter> */}
          {/* <rect width="100%" height="100%" x="0" y="0" fill="none" /> */}
    
          {/* <rect width="100%" height="10%" x="0" y="0" fill="#b3b3b3" /> */}

          <rect width="100%" height="100%" x="0" y="0" fill="rgb(200,200,200)" fillOpacity="1"/>
    
          {/* <rect width="100%" height="10%" x="0" y="0"  filter="url(#highlight10)" />
          <rect width="100%" height="10%" x="0" y="90%" filter="url(#shadow)" /> */}
          {/* <rect width="100%" height="10%" x="0" y="90%" fill="#9b9b9b" /> */}
        </svg>
  )
}