import styles from './DockBackground.module.sass'

type DockBackgroundProps = {
}

export const DockBackground: React.FC<DockBackgroundProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none" width="100%" height="100%">
      {/* <filter id="highlight10">
        <feSpecularLighting
          specularExponent="4"
          lighting-color="#ffffff">
        </feSpecularLighting>
      </filter>
      <filter id="shadow">
        <feFlood
          flood-color="#9b9b9b"
          flood-opacity="0.9"
        />
      </filter> */}
      <rect width="100%" height="100%" x="0" y="0" fill="#b3b3b3" />

      {/* <rect width="100%" height="10%" x="0" y="0" fill="#b3b3b3" /> */}
      {/* <rect width="100%" height="9%" x="0" y="91%" fill="#000" stroke="#000" stroke-width="1" opacity="0.3"/>
      <rect width="0.3%" height="100%" x="99.7" y="0%" fill="#000" fillOpacity="0.2"/>
      <rect width="100%" height="6%" x="0" y="0" fill="#fff" fillOpacity="0.5"/>
      <rect width="0.15%" height="100%" x="0" y="0%" fill="#fff" stroke-width="1" stroke="#fff" opacity="0.2"/> */}

      {/* <rect width="100%" height="10%" x="0" y="0"  filter="url(#highlight10)" />
      <rect width="100%" height="10%" x="0" y="90%" filter="url(#shadow)" /> */}
      {/* <rect width="100%" height="10%" x="0" y="90%" fill="#9b9b9b" /> */}
    </svg>
  )
}