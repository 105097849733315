import { ProfileTab } from "../ProfileTab/ProfileTab"

const ProjectManagementTab: React.FC = () => {
  return (
    <ProfileTab>
      <div>
        <p>Economics/Law background. .</p>
        <ul>
          <li>Agile, Scrum, </li>
        </ul>
        <ul>
          <li>PMBOK</li>
        </ul>
        <ul>
          <li>IP copyright, </li>
        </ul>
        <ul>
          <li>Contract</li>
        </ul>
        <ul>
          <li>Communication channels</li>
        </ul>
        <ul>
          <li>Team leadership, conflict resolution, documentation</li>
        </ul>
      </div>
    </ProfileTab>
  )
}

export default ProjectManagementTab


