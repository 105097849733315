import styles from './AppsDockIcon.module.sass'

type AppsDockIconProps = {
}

export const AppsDockIcon: React.FC<AppsDockIconProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <g fill="none" stroke="gray" stroke-linecap="round" stroke-miterlimit="10" stroke-width="5">
        <rect width="15.26" height="15.26" x="29.5" y="30.5" rx="3.76" ry="3.76" />
        <rect width="15.26" height="15.26" x="53.24" y="30.5" rx="3.76" ry="3.76" />
        <rect width="15.26" height="15.26" x="53.24" y="54.24" rx="3.76" ry="3.76" />
        <rect width="15.26" height="15.26" x="29.5" y="54.24" rx="3.76" ry="3.76" />
        <rect width="67" height="67" x="15" y="17" rx="13.91" ry="13.91" />
      </g>
    </svg>
  )
}