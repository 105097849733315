import styles from './PageLayout.module.sass'

type PageLayoutProps = {
  children?: React.ReactNode
}

export const PageLayout: React.FC<PageLayoutProps> = ({
  children
}) => {
  return (
    <div className={styles.pageLayout}>
      <div className={styles.scrollContainer}>
        <div className={styles.pageContainer}>
          <div className={styles.page}>
            <div className={styles.contentContainer}>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}