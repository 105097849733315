import { useContext } from 'react';
import useWindowManager from '../../../../../hooks/useWindowManager';
import ArrowLeft from '../../../../ui/icons/ArrowLeft/ArrowLeft';
import { InfoButtonModal } from './InfoButtonModal/InfoButtonModal';
import styles from './WindowTop.module.sass'
import mobileStyles from './WindowTopMobile.module.sass'
import AppContext from '../../../../App/AppContext';

type WindowTopMobileProps = {
  title: string;
  windowId: string;
  setShowInfoBox: React.Dispatch<React.SetStateAction<boolean>>
}

export const WindowTopMobile: React.FC<WindowTopMobileProps> = ({
  title,
  windowId,
  setShowInfoBox
}) => {

  const { windows, setWindows } = useContext(AppContext)
  const { closeWindow, setFrontWindow } = useWindowManager(windows, setWindows)

  const backToApps = () => {
    closeWindow(windowId)

  }

  return windowId == 'apps' ? null :
    <div className={mobileStyles.windowTopMobile}>
      <div className={mobileStyles.leftContainer} >
        <div className={mobileStyles.backArrowContainer} onClick={backToApps}>
        <ArrowLeft />
        </div>
      </div>
      <div className={mobileStyles.centerContainer}>
        <div className={mobileStyles.strokesContainer}>
          <div className={styles.strokes}></div>
        </div>
        <div className={`${styles.title} ${mobileStyles.title}`}>
          <h3>{title}</h3>
        </div>
        <div className={mobileStyles.strokesContainer}>
          <div className={styles.strokes}></div>
        </div>
      </div>
      <div className={mobileStyles.rightContainer}>
        <div className={mobileStyles.infoButtonContainer}>
          <InfoButtonModal setShowInfoBox={setShowInfoBox} />
        </div>
      </div>
    </div>
}