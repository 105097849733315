import { Notification } from '../../../../types/types';
import { Modal } from '../../../layouts/Modal/Modal';
import ArrowFlatLeft from '../../../ui/icons/ArrowFlatLeft/ArrowFlatLeft';
import Circle from '../../../ui/icons/Circle/Circle';
import { DetailedNotification } from '../DetailedNotification/DetailedNotification';
import styles from './SingleLineNotification.module.sass'

type SingleLineNotificationProps = {
  notification: Notification
  toggleShowDetails?: () => void
}

export const SingleLineNotification: React.FC<SingleLineNotificationProps> = ({
  notification,
  toggleShowDetails
}) => {
  return (
    <div className={`${styles.container} ${notification.isNew ? styles.new : ""}`}>

      <div className={styles.detailsToggle} onClick={toggleShowDetails ? toggleShowDetails : undefined}>
        <ArrowFlatLeft />
      </div>

      <h4>{notification.subject}</h4>
      {notification.isNew ? <Circle /> : null}
    </div>
  )
}