import { useRef, useState, useEffect, useContext, SetStateAction } from 'react'
import styles from './WindowTemplate.module.sass'
import useDraggable from '../../../../hooks/useDraggable'
import { Size, LayoutParams } from '../../../../types/types'
import ResizableWindow from '../ResizableWindow/ResizableWindow'
import WindowTopDesktop from './WindowTop/WindowTopDesktop'
import { InfoBox } from '../InfoBox/InfoBox'
import { WindowTopMobile } from './WindowTop/WindowTopMobile'
import useViewportSize from '../../../../hooks/useViewportSize'

export type WindowTemplateProps = {
  windowId: string;
  isThisWindowOpen: boolean;
  isThisWindowMinimised: boolean;
  isThisWindowFullScreen: boolean;
  isWindowInFront: boolean;
  openThisWindow: () => void;
  closeThisWindow: () => void;
  title: string;
  zIndex: number;
  bringThisWindowToFront: () => void;
  initialLayoutParams: LayoutParams;
  minimumSize: Size;
  boundingElementRef: React.RefObject<HTMLDivElement>;
  infoText: string
}

const WindowTemplate: React.FC<WindowTemplateProps> = ({
  windowId,
  isThisWindowOpen: isOpen,
  isThisWindowMinimised: isMinimised,
  isThisWindowFullScreen: isFullScreen,
  isWindowInFront: isInFront,
  openThisWindow: openWindow,
  closeThisWindow: closeWindow,
  title,
  zIndex,
  bringThisWindowToFront: bringWindowToFront,
  initialLayoutParams,
  minimumSize,
  boundingElementRef,
  infoText,
  children,
}) => {

  const windowRef = useRef<HTMLDivElement>(null)

  const [layoutParams, setLayoutParams] = useState<LayoutParams>(initialLayoutParams)
  const [showInfoBox, setShowInfoBox] = useState<boolean>(false)
  const [appliedViewportSizeChange, setAppliedViewportSizeChange] = useState<Size>({ width: 0, height: 0 })
  const { isMobile, viewportSize, viewportSizeChange } = useViewportSize()
  const { startDragging } = useDraggable({
    windowRef,
    layoutParams,
    setLayoutParams,
    boundingElementRef
  });

  const display: React.CSSProperties = isOpen && !isMinimised ? { display: 'block' } : { display: 'none' }

  const positionStyles: React.CSSProperties =
    isMobile ?
      isInFront ?
        { position: "relative", top: 0, left: 0, bottom: 0, right: 0 } :
        { display: "none" } :
      isFullScreen ?
        { position: "relative", top: 0, left: 0, bottom: 0, right: 0 } :
        { position: "absolute", top: layoutParams.top, left: layoutParams.left }

  const zIndexStyle: React.CSSProperties = { zIndex: zIndex }

  const getSizeStyles = () => {
    if (isMobile || isFullScreen) {
      return ({ width: '100%', height: '100%' })
    }
    else {
      return ({ width: layoutParams.width, height: layoutParams.height }
      )
    }
  }

  const mouseDownOnWindowTop = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isFullScreen) return
    bringWindowToFront()
    startDragging(e, layoutParams)
  }

  const moveWindowOnViewportResize = () => {
    const { width: viewportWidthChange, height: viewportHeightChange } = viewportSizeChange;
    const deltaWidth = viewportWidthChange - appliedViewportSizeChange.width
    const deltaHeight = viewportHeightChange - appliedViewportSizeChange.height

    if (deltaWidth === 0 && deltaHeight === 0) return

    setLayoutParams(prev => {
      const prevViewportWidth = viewportSize.width - deltaWidth
      const totalLeftRight = prevViewportWidth - prev.width
      const leftProportion = prev.left / totalLeftRight

      const prevViewportHeight = viewportSize.height - deltaHeight
      const totalTopBottom = prevViewportHeight - prev.height
      const topProportion = prev.top / totalTopBottom

      return {
        ...prev,
        left: prev.left + (deltaWidth * leftProportion),
        top: prev.top + (deltaHeight * topProportion),
      }
    });

    setAppliedViewportSizeChange(viewportSizeChange)
  }


  useEffect(() => {
    moveWindowOnViewportResize()
  }, [viewportSizeChange])

  return (
    <div
      ref={windowRef}
      className={styles.window + ' ' + windowId + 'Window' + ' ' + `${isFullScreen && styles.fullscreen}`}
      style={{
        ...positionStyles, ...getSizeStyles(), ...display, ...zIndexStyle
      }}
    >
      {isMobile ?

        <WindowTopMobile
          title={title}
          windowId={windowId}
          setShowInfoBox={setShowInfoBox}
        /> :

        <WindowTopDesktop
          title={title}
          windowId={windowId}
          mouseDownOnWindowTop={mouseDownOnWindowTop}
          setShowInfoBox={setShowInfoBox}
        />}

      <ResizableWindow
        windowRef={windowRef}
        windowId={windowId}
        layoutParams={layoutParams}
        setLayoutParams={setLayoutParams}
        minimumSize={minimumSize}
        boundingElementRef={boundingElementRef}
      />
      <div className={styles.windowBody} onMouseDown={bringWindowToFront}>
        {showInfoBox &&
          <InfoBox
            windowTitle={title}
            infoText={infoText}
            setShowInfoBox={setShowInfoBox}
          />}
        {children}
      </div>

    </div>
  )
}

export default WindowTemplate