import ProjectTemplate from "../ProjectTemplate/ProjectTemplate";

const UrbanitePortfolioItem = () => {
  return (
    <ProjectTemplate
      name="Urbanite"
      url="https://urbanite.io"
      overviewDescription="react project"
      mainImageSrc='/UrbaniteThumbnail.png'
      techStackDescription='react project'
    >
    </ProjectTemplate>
  )
}

export default UrbanitePortfolioItem