import { useContext, useEffect, useState } from 'react';
import { BoundingArea, LayoutParams, Position, Size } from '../../../../types/types';
import styles from './ResizableWindow.module.sass'
import AppContext from '../../../App/AppContext';

type ResizableWindowProps = {
  windowRef: React.RefObject<HTMLDivElement>
  windowId: string
  layoutParams: LayoutParams;
  setLayoutParams: React.Dispatch<React.SetStateAction<LayoutParams>>;
  minimumSize: Size;
  boundingElementRef: React.RefObject<HTMLDivElement>;
}

const ResizableWindow: React.FC<ResizableWindowProps> = ({
  windowRef,
  windowId,
  layoutParams,
  setLayoutParams,
  minimumSize,
  boundingElementRef
}) => {

  const [boundingArea, setBoundingArea] = useState<BoundingArea>()
  const { windows } = useContext(AppContext)

  useEffect(() => {
    if (boundingElementRef.current) {

      const { top: backgroundTop, bottom: backgroundBottom, left: backgroundLeft, right: backgroundRight, height: backgroundHeight, width: backgroundWidth } = boundingElementRef.current.getBoundingClientRect()
      const windowHeight = window.innerHeight
      const windowWidth = window.innerWidth

      setBoundingArea({
        minTop: backgroundTop,
        minBottom: windowHeight - backgroundBottom,
        minLeft: backgroundLeft,
        minRight: windowWidth - backgroundRight,
        maxHeight: backgroundHeight,
        maxWidth: backgroundWidth
      })
    }
  }, [boundingElementRef])

  const mouseDownResize = (e: React.MouseEvent<HTMLDivElement>, direction: string) => {

    if (windows[windowId].isFullScreen) return
    if (!boundingArea) return

    let startX = e.clientX
    let startY = e.clientY

    const mouseMoveHandler = (moveEvent: MouseEvent) => {
      mouseMoveResize(
        moveEvent,
        direction,
        startX,
        startY
      )
    }

    document.addEventListener('mousemove', mouseMoveHandler)
    document.body.style.userSelect = 'none'
    document.addEventListener('mouseup', () => {
      document.body.style.userSelect = 'auto'
      document.removeEventListener('mousemove', mouseMoveHandler)
    }, { once: true })
    // e.stopPropagation()
  }

  const mouseMoveResize = (
    moveEvent: MouseEvent,
    direction: string,
    startX: number,
    startY: number,
  ) => {

    if (!boundingArea) return

    const calculateResizedPosition = (prevPosition: Position): Position => {

      const constrainToBoundingArea = (value: number, min: number, max: number) => Math.max(0, Math.min(Math.max(value, min), max))

      if (!boundingArea) {
        const samePosition = { ...prevPosition }
        return samePosition
      }

      let resizedTop
      let resizedRight
      let resizedBottom
      let resizedLeft

      const xDiff = moveEvent.clientX - startX
      const yDiff = moveEvent.clientY - startY

      switch (direction) {
        case 'north':
        case 'northEast':
        case 'northWest':
          const calculatedTop = prevPosition.top + yDiff
          resizedTop = constrainToBoundingArea(calculatedTop, boundingArea.minTop, boundingArea.maxHeight)
          resizedBottom = prevPosition.bottom
          break;
        case 'south':
        case 'southEast':
        case 'southWest':
          const calculatedBottom = prevPosition.bottom - yDiff
          resizedBottom = constrainToBoundingArea(calculatedBottom, boundingArea.minBottom, boundingArea.maxHeight)
          resizedTop = prevPosition.top
          break;
        default:
          resizedTop = prevPosition.top
          resizedBottom = prevPosition.bottom
      }

      switch (direction) {
        case 'west':
        case 'northWest':
        case 'southWest':
          const calculatedLeft = prevPosition.left + xDiff
          resizedLeft = constrainToBoundingArea(calculatedLeft, boundingArea.minLeft, boundingArea.maxWidth)
          resizedRight = prevPosition.right
          break;
        case 'east':
        case 'northEast':
        case 'southEast':
          const calculatedRight = prevPosition.right - xDiff
          resizedRight = constrainToBoundingArea(calculatedRight, boundingArea.minRight, boundingArea.maxWidth)
          resizedLeft = prevPosition.left
          break;
        default:
          resizedRight = prevPosition.right
          resizedLeft = prevPosition.left
      }

      const resizedPosition = { top: resizedTop, left: resizedLeft, right: resizedRight, bottom: resizedBottom }
      return resizedPosition
    }


    const startPos: Position = {
      top: layoutParams.top,
      left: layoutParams.left,
      bottom: boundingArea.maxHeight - (layoutParams.top + layoutParams.height),
      right: boundingArea.maxWidth - (layoutParams.left + layoutParams.width)
    }

    setLayoutParams(prev => {
      const pos = calculateResizedPosition(startPos)
      return ({
        top: pos.top,
        left: pos.left,
        width: boundingArea.maxWidth - (pos.left + pos.right),
        height: boundingArea.maxHeight - (pos.top + pos.bottom)
      })
    }
    )
  }

  const addClassIfFullScreen = () => {
    const resultClass =
      windows[windowId].isFullScreen ?
        styles.fullScreen :
        null
    return resultClass
  }


  return (
    <div className={styles.resizable}>

      <div
        className={styles.resizingArea + ' ' + `${addClassIfFullScreen()}` + ' ' + styles.edge + ' ' + styles.end + ' ' + styles.northern + ' ' + styles.north}
        onMouseDown={(e) => mouseDownResize(e, 'north')}
      ></div>

      <div
        className={styles.resizingArea + ' ' + `${addClassIfFullScreen()}` + ' ' + styles.corner + ' ' + styles.end + ' ' + styles.northern + ' ' + styles.eastern + ' ' + styles.northEast}
        onMouseDown={(e) => mouseDownResize(e, 'northEast')}
      ></div>

      <div className={styles.resizingArea + ' ' + `${addClassIfFullScreen()}` + ' ' + styles.corner + ' ' + styles.side + ' ' + styles.northern + ' ' + styles.eastern + ' ' + styles.northEast}
        onMouseDown={(e) => mouseDownResize(e, 'northEast')}
      ></div>

      <div
        className={styles.resizingArea + ' ' + `${addClassIfFullScreen()}` + ' ' + styles.edge + ' ' + styles.side + ' ' + styles.eastern + ' ' + styles.east}
        onMouseDown={(e) => mouseDownResize(e, 'east')}
      ></div>

      <div
        className={styles.resizingArea + ' ' + `${addClassIfFullScreen()}` + ' ' + styles.corner + ' ' + styles.side + ' ' + styles.southern + ' ' + styles.eastern + ' ' + styles.southEast}
        onMouseDown={(e) => mouseDownResize(e, 'southEast')}
      ></div>

      <div
        className={styles.resizingArea + ' ' + `${addClassIfFullScreen()}` + ' ' + styles.corner + ' ' + styles.end + ' ' + styles.southern + ' ' + styles.eastern + ' ' + styles.southEast}
        onMouseDown={(e) => mouseDownResize(e, 'southEast')}
      ></div>

      <div
        className={styles.resizingArea + ' ' + `${addClassIfFullScreen()}` + ' ' + styles.edge + ' ' + styles.end + ' ' + styles.southern + ' ' + styles.south}
        onMouseDown={(e) => mouseDownResize(e, 'south')}
      ></div>

      <div className={styles.resizingArea + ' ' + `${addClassIfFullScreen()}` + ' ' + styles.corner + ' ' + styles.end + ' ' + styles.southern + ' ' + styles.western + ' ' + styles.southWest}
        onMouseDown={(e) => mouseDownResize(e, 'southWest')}
      ></div>

      <div
        className={styles.resizingArea + ' ' + `${addClassIfFullScreen()}` + ' ' + styles.corner + ' ' + styles.side + ' ' + styles.southern + ' ' + styles.western + ' ' + styles.southWest}
        onMouseDown={(e) => mouseDownResize(e, 'southWest')}
      ></div>

      <div
        className={styles.resizingArea + ' ' + `${addClassIfFullScreen()}` + ' ' + styles.edge + ' ' + styles.side + ' ' + styles.western + ' ' + styles.west}
        onMouseDown={(e) => mouseDownResize(e, 'west')}
      ></div>

      <div
        className={styles.resizingArea + ' ' + `${addClassIfFullScreen()}` + ' ' + styles.corner + ' ' + styles.side + ' ' + styles.northern + ' ' + styles.western + ' ' + styles.northWest}
        onMouseDown={(e) => mouseDownResize(e, 'northWest')}
      ></div>

      <div
        className={styles.resizingArea + ' ' + `${addClassIfFullScreen()}` + ' ' + styles.corner + ' ' + styles.end + ' ' + styles.northern + ' ' + styles.western + ' ' + styles.northWest}
        onMouseDown={(e) => mouseDownResize(e, 'northWest')}
      ></div>

    </div>
  )
}



export default ResizableWindow