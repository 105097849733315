import { useContext } from 'react';
import useWindowManager from '../../../../hooks/useWindowManager';
import AppContext from '../../../App/AppContext';
import ContactIcon from '../../../Dock/Apps/AppIconTemplate/ContactIcon';
import LibraryIcon from '../../../Dock/Apps/AppIconTemplate/LibraryIcon';
import PortfolioIcon from '../../../Dock/Apps/AppIconTemplate/PortfolioIcon';
import ProfileIcon from '../../../Dock/Apps/AppIconTemplate/ProfileIcon';
import styles from './AppsWindow.module.sass'
import { AppTemplate } from './AppTemplate/AppTemplate';

type AppsWindowProps = {}

export const AppsWindow: React.FC<AppsWindowProps> = ({
}) => {

  const { windows, setWindows } = useContext(AppContext);

  const {
    openWindow,
    isWindowOpen,
    setMinimised,
    bringWindowToFront
  } = useWindowManager(windows, setWindows)

  const openOnTouch = (windowId: string) => {
    openWindow(windowId)
    setMinimised(windowId, false)
    bringWindowToFront(windowId)
  }

  return (
    <div className={styles.appsWindow}>

      <div className={`${styles.appGridItem} ${styles.profileApp}`}>
        <AppTemplate
          appId={"profile"}
          appTitle={"PROFILE"}
          appImage={<ProfileIcon />}
          openOnTouch={openOnTouch}
          isWindowOpen={isWindowOpen}
        />
      </div>

      <div className={`${styles.appGridItem} ${styles.portfolioApp}`}>
        <AppTemplate
          appId={"portfolio"}
          appTitle={"PORTFOLIO"}
          appImage={<PortfolioIcon />}
          openOnTouch={openOnTouch}
          isWindowOpen={isWindowOpen}
        />
      </div>

      <div className={`${styles.appGridItem} ${styles.libraryApp}`}>
        <AppTemplate
          appId={"library"}
          appTitle={"LIBRARY"}
          appImage={<LibraryIcon />}
          openOnTouch={openOnTouch}
          isWindowOpen={isWindowOpen}
        />
      </div>

      <div className={`${styles.appGridItem} ${styles.contactApp}`}>
        <AppTemplate
          appId={"contact"}
          appTitle={"CONTACT"}
          appImage={<ContactIcon />}
          openOnTouch={openOnTouch}
          isWindowOpen={isWindowOpen}
        />
      </div>

    </div>
  )
}