import CloseButton from '../../../ui/CloseButton/CloseButton';
import styles from './InfoBox.module.sass'

type InfoBoxProps = {
  windowTitle: string;
  infoText: string
  setShowInfoBox: React.Dispatch<React.SetStateAction<boolean>>;
}

export const InfoBox: React.FC<InfoBoxProps> = ({
  windowTitle,
  infoText,
  setShowInfoBox
}) => {


  return (
    <div className={styles.container}>
      <div className={`${styles.infoBox} global_box`}>
        <div className={styles.topRow}>
          <div className={styles.windowTitle}>
            <h3>–– INFO BOX ––</h3>
            {/* <h3>INFO | ABOUT THIS WINDOW</h3> */}
          </div>

          <div className={styles.closeButtonContainer} onClick={e => setShowInfoBox(false)}>
            <CloseButton />
          </div>
        </div>
        <div className={styles.messageBody}>
          <h4>
            ABOUT THE {windowTitle} WINDOW:
          </h4>
          <p>
            {infoText}
          </p>
        </div>
      </div>
    </div>
  )
}