import styles from './ProfileDockIcon.module.sass'

type ProfileDockIconProps = {
}

export const ProfileDockIcon: React.FC<ProfileDockIconProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <path fill="gray" stroke="gray" stroke-miterlimit="10" stroke-width="1.96" d="M67.53 59.07c-.56-6.34-2.28-10.08-7.73-13.13l-1.81 1.86c5.08 2.56 6.59 5.76 7.08 11.53v5.44H34.91v-4.86c.28-8.04 6.97-14.26 15.03-14.27 1.94 0 3.83-.6 5.41-1.71.41-.29.81-.61 1.17-.97 3.74-3.63 3.82-9.61.19-13.35s-9.61-3.82-13.35-.19-3.82 9.61-.19 13.35c.47.48.98.91 1.54 1.28-6.96 2.16-11.91 8.34-12.27 15.6v7.57h35.09v-1.24c.02-1.49.05-6.37 0-6.92ZM42.91 36.2c0-4 3.31-7.22 7.34-7.08 3.64.13 6.65 3.13 6.8 6.77.17 4.02-3.03 7.35-7.02 7.37-3.91.03-7.09-3.12-7.12-7.03v-.04Z" />
      <circle cx="50" cy="50" r="36.23" fill="none" stroke="gray" stroke-miterlimit="10" stroke-width="4.59" />
    </svg>
  )
}